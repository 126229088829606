import { useQuery as i, useMutation as c } from "@tanstack/react-query";
import o from "axios";
const jd = {
  cash_advance_offer_created: "cash_advance_offer_created",
  cash_advance_offer_accepted: "cash_advance_offer_accepted",
  cash_advance_top_up_eligible: "cash_advance_top_up_eligible",
  cash_advance_paid_off: "cash_advance_paid_off",
  cash_advance_closed: "cash_advance_closed",
  cash_advance_offer_closed: "cash_advance_offer_closed",
  flex_loan_offer_accepted: "flex_loan_offer_accepted",
  flex_loan_closed: "flex_loan_closed",
  flex_loan_offer_created: "flex_loan_offer_created",
  flex_loan_offer_closed: "flex_loan_offer_closed",
  capital_product_offercreated: "capital_product_offer.created",
  capital_product_offerclosed: "capital_product_offer.closed",
  capital_product_offerdenied: "capital_product_offer.denied",
  capital_productcreated: "capital_product.created",
  capital_productclosed: "capital_product.closed",
  capital_product_applicationupdated: "capital_product_application.updated",
  opt_in: "opt_in",
  fis_opt_in_merchant_account: "fis_opt_in_merchant_account",
  fis_opt_in_bank_account: "fis_opt_in_bank_account",
  wallet_applicationapproved: "wallet_application.approved",
  wallet_applicationdenied: "wallet_application.denied",
  wallet_accountactive: "wallet_account.active",
  wallet_accountinactive: "wallet_account.inactive",
  wallet_transactionsucceeded: "wallet_transaction.succeeded",
  wallet_transactionpending: "wallet_transaction.pending",
  wallet_transactioncanceled: "wallet_transaction.canceled",
  wallet_transactionarchived: "wallet_transaction.archived",
  wallet_transactionfailed: "wallet_transaction.failed"
}, Xd = {
  normal: "normal",
  sync: "sync"
}, Jd = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, Zd = {
  enabled: "enabled",
  disabled: "disabled"
}, t_ = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, e_ = {
  automatically_verified: "automatically_verified",
  pending_automatic_verification: "pending_automatic_verification",
  pending_manual_verification: "pending_manual_verification",
  manually_verified: "manually_verified",
  verification_expired: "verification_expired",
  verification_failed: "verification_failed",
  database_matched: "database_matched",
  database_insights_pass: "database_insights_pass",
  database_insights_pass_with_caution: "database_insights_pass_with_caution",
  database_insights_fail: "database_insights_fail"
}, n_ = {
  Error: "Error",
  Warning: "Warning"
}, r_ = {
  admin: "admin",
  user: "user",
  controller: "controller"
}, s_ = {
  offer: "offer",
  no_offer: "no_offer",
  manual_review: "manual_review"
}, a_ = {
  terms_of_service: "terms_of_service",
  state_disclosure: "state_disclosure",
  net_top_up_itemization: "net_top_up_itemization"
}, o_ = {
  payout: "payout",
  balance_transaction: "balance_transaction"
}, u_ = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT"
}, i_ = {
  mfa: "mfa",
  otp: "otp",
  account_recovery: "account_recovery"
}, c_ = {
  very_high: "very_high",
  high: "high",
  medium: "medium",
  low: "low"
}, y_ = {
  capital_product_plaid_bank_linking: "capital_product_plaid_bank_linking",
  capital_product_acceptance: "capital_product_acceptance",
  merchant_bank_card_transaction: "merchant_bank_card_transaction",
  merchant_bank_transfer: "merchant_bank_transfer"
}, d_ = {
  pending_response: "pending_response",
  completed: "completed"
}, __ = {
  before_marketing: "before_marketing",
  after_sign_up: "after_sign_up"
}, p_ = {
  capital_product_offers_table: "capital_product_offers_table",
  capital_product_applications_table: "capital_product_applications_table",
  capital_products_table: "capital_products_table"
}, l_ = {
  completed: "completed",
  in_progress: "in_progress",
  not_started: "not_started",
  failed: "failed",
  expired: "expired"
}, m_ = {
  shopify: "shopify",
  amazon: "amazon",
  square: "square",
  ebay: "ebay",
  walmart: "walmart",
  etsy: "etsy",
  woo_commerce: "woo_commerce",
  quickbooks: "quickbooks",
  xero: "xero",
  net_suite: "net_suite",
  magneto: "magneto",
  freshbooks: "freshbooks",
  zohobooks: "zohobooks",
  clover: "clover",
  paypal: "paypal"
}, g_ = {
  payments: "payments",
  accounting: "accounting"
}, q_ = {
  pending: "pending",
  available: "available",
  disconnected: "disconnected",
  disabled: "disabled",
  link_error: "link_error",
  error: "error"
}, f_ = {
  duplicate: "duplicate",
  incorrect_amount: "incorrect_amount",
  incorrect_receiving_account: "incorrect_receiving_account",
  date_earlier_than_intended: "date_earlier_than_intended",
  date_later_than_intended: "date_later_than_intended"
}, O_ = {
  R01: "R01",
  R02: "R02",
  R03: "R03",
  R04: "R04",
  R05: "R05",
  R06: "R06",
  R07: "R07",
  R08: "R08",
  R09: "R09",
  R10: "R10",
  R11: "R11",
  R12: "R12",
  R13: "R13",
  R14: "R14",
  R15: "R15",
  R16: "R16",
  R17: "R17",
  R18: "R18",
  R19: "R19",
  R20: "R20",
  R21: "R21",
  R22: "R22",
  R23: "R23",
  R24: "R24",
  R25: "R25",
  R26: "R26",
  R27: "R27",
  R28: "R28",
  R29: "R29",
  R30: "R30",
  R31: "R31",
  R32: "R32",
  R33: "R33",
  R34: "R34",
  R35: "R35",
  R36: "R36",
  R37: "R37",
  R38: "R38",
  R39: "R39",
  R45: "R45",
  C01: "C01",
  C02: "C02",
  C03: "C03",
  C05: "C05",
  C06: "C06",
  C07: "C07",
  C08: "C08",
  C09: "C09",
  C13: "C13",
  C14: "C14",
  NUMBER_900: 900,
  NUMBER_901: 901,
  NUMBER_902: 902,
  NUMBER_903: 903,
  NUMBER_904: 904,
  NUMBER_905: 905,
  NUMBER_907: 907,
  NUMBER_908: 908,
  NUMBER_909: 909,
  NUMBER_910: 910,
  NUMBER_911: 911,
  NUMBER_912: 912,
  NUMBER_914: 914,
  NUMBER_915: 915,
  NUMBER_916: 916,
  NUMBER_917: 917,
  NUMBER_918: 918,
  NUMBER_919: 919,
  NUMBER_920: 920,
  NUMBER_921: 921,
  NUMBER_922: 922,
  NUMBER_990: 990
}, h_ = {
  prevent_business_onboarding: "prevent_business_onboarding",
  suspend_card_account: "suspend_card_account"
}, K_ = {
  manual: "manual",
  auto_expiration: "auto_expiration",
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  lift_repeated_failed_nsf_repayments: "lift_repeated_failed_nsf_repayments",
  unverified_bank_account: "unverified_bank_account",
  lift_unverified_bank_account: "lift_unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  lift_recent_non_nsf_repayment_returns: "lift_recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  lift_first_repayment_returned_nsf: "lift_first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  lift_first_auto_repayment_returned_nsf: "lift_first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  lift_no_recent_sales: "lift_no_recent_sales",
  plaid_transaction_review: "plaid_transaction_review",
  generic_application_denial: "generic_application_denial"
}, b_ = {
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  unverified_bank_account: "unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  fraud: "fraud",
  bankruptcy: "bankruptcy",
  active_payment_plan_high_risk: "active_payment_plan_high_risk",
  payment_processor_transition: "payment_processor_transition",
  shared_email_across_platforms: "shared_email_across_platforms",
  bank_account_high_servicing_risk: "bank_account_high_servicing_risk",
  servicing_risk: "servicing_risk",
  generic_application_denial: "generic_application_denial"
}, k_ = {
  unverified_bank_account: "unverified_bank_account",
  repayment_returns: "repayment_returns",
  other: "other"
}, M_ = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, P_ = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly"
}, x_ = {
  cx: "cx",
  pending_funding: "pending_funding",
  too_many_missed_repayments: "too_many_missed_repayments",
  administrative_ach_return: "administrative_ach_return",
  awaiting_retry_non_nsf_return: "awaiting_retry_non_nsf_return",
  pending_reversal_completion: "pending_reversal_completion"
}, B_ = {
  gross_sales: "gross_sales",
  net_payouts: "net_payouts"
}, R_ = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, F_ = {
  pending: "pending",
  approved: "approved",
  denied: "denied",
  manual_review: "manual_review"
}, E_ = {
  other: "other",
  disbursements: "disbursements",
  serviceability: "serviceability"
}, C_ = {
  mfa_phone_number: "mfa_phone_number",
  unverified_phone_number: "unverified_phone_number"
}, v_ = {
  NUMBER_93: "93",
  NUMBER_355: "355",
  NUMBER_213: "213",
  NUMBER_1684: "1684",
  NUMBER_376: "376",
  NUMBER_244: "244",
  NUMBER_1264: "1264",
  NUMBER_672: "672",
  NUMBER_1268: "1268",
  NUMBER_54: "54",
  NUMBER_374: "374",
  NUMBER_297: "297",
  NUMBER_61: "61",
  NUMBER_43: "43",
  NUMBER_994: "994",
  NUMBER_1242: "1242",
  NUMBER_973: "973",
  NUMBER_880: "880",
  NUMBER_1246: "1246",
  NUMBER_375: "375",
  NUMBER_32: "32",
  NUMBER_501: "501",
  NUMBER_229: "229",
  NUMBER_1441: "1441",
  NUMBER_975: "975",
  NUMBER_591: "591",
  NUMBER_387: "387",
  NUMBER_267: "267",
  NUMBER_55: "55",
  NUMBER_246: "246",
  NUMBER_1284: "1284",
  NUMBER_673: "673",
  NUMBER_359: "359",
  NUMBER_226: "226",
  NUMBER_257: "257",
  NUMBER_855: "855",
  NUMBER_237: "237",
  NUMBER_1: "1",
  NUMBER_238: "238",
  NUMBER_1345: "1345",
  NUMBER_236: "236",
  NUMBER_235: "235",
  NUMBER_56: "56",
  NUMBER_86: "86",
  NUMBER_57: "57",
  NUMBER_269: "269",
  NUMBER_682: "682",
  NUMBER_506: "506",
  NUMBER_385: "385",
  NUMBER_53: "53",
  NUMBER_599: "599",
  NUMBER_357: "357",
  NUMBER_420: "420",
  NUMBER_243: "243",
  NUMBER_45: "45",
  NUMBER_253: "253",
  "1-767": "1-767",
  NUMBER_1809: "1809",
  NUMBER_1829: "1829",
  NUMBER_1849: "1849",
  NUMBER_670: "670",
  NUMBER_593: "593",
  NUMBER_20: "20",
  NUMBER_503: "503",
  NUMBER_240: "240",
  NUMBER_291: "291",
  NUMBER_372: "372",
  NUMBER_251: "251",
  NUMBER_500: "500",
  NUMBER_298: "298",
  NUMBER_679: "679",
  NUMBER_358: "358",
  NUMBER_33: "33",
  NUMBER_689: "689",
  NUMBER_241: "241",
  NUMBER_220: "220",
  NUMBER_995: "995",
  NUMBER_49: "49",
  NUMBER_233: "233",
  NUMBER_350: "350",
  NUMBER_30: "30",
  NUMBER_299: "299",
  NUMBER_1473: "1473",
  NUMBER_1671: "1671",
  NUMBER_502: "502",
  NUMBER_441481: "441481",
  NUMBER_224: "224",
  NUMBER_245: "245",
  NUMBER_592: "592",
  NUMBER_509: "509",
  NUMBER_504: "504",
  NUMBER_852: "852",
  NUMBER_36: "36",
  NUMBER_354: "354",
  NUMBER_91: "91",
  NUMBER_62: "62",
  NUMBER_98: "98",
  NUMBER_964: "964",
  NUMBER_353: "353",
  NUMBER_441624: "441624",
  NUMBER_972: "972",
  NUMBER_39: "39",
  NUMBER_225: "225",
  NUMBER_1876: "1876",
  NUMBER_81: "81",
  NUMBER_441534: "441534",
  NUMBER_962: "962",
  NUMBER_7: "7",
  NUMBER_254: "254",
  NUMBER_686: "686",
  NUMBER_383: "383",
  NUMBER_965: "965",
  NUMBER_996: "996",
  NUMBER_856: "856",
  NUMBER_371: "371",
  NUMBER_961: "961",
  NUMBER_266: "266",
  NUMBER_231: "231",
  NUMBER_218: "218",
  NUMBER_423: "423",
  NUMBER_370: "370",
  NUMBER_352: "352",
  NUMBER_853: "853",
  NUMBER_389: "389",
  NUMBER_261: "261",
  NUMBER_265: "265",
  NUMBER_60: "60",
  NUMBER_960: "960",
  NUMBER_223: "223",
  NUMBER_356: "356",
  NUMBER_692: "692",
  NUMBER_222: "222",
  NUMBER_230: "230",
  NUMBER_262: "262",
  NUMBER_52: "52",
  NUMBER_691: "691",
  NUMBER_373: "373",
  NUMBER_377: "377",
  NUMBER_976: "976",
  NUMBER_382: "382",
  "1-664": "1-664",
  NUMBER_212: "212",
  NUMBER_258: "258",
  NUMBER_95: "95",
  NUMBER_264: "264",
  NUMBER_674: "674",
  NUMBER_977: "977",
  NUMBER_31: "31",
  NUMBER_687: "687",
  NUMBER_64: "64",
  NUMBER_505: "505",
  NUMBER_227: "227",
  NUMBER_234: "234",
  NUMBER_683: "683",
  NUMBER_850: "850",
  NUMBER_1670: "1670",
  NUMBER_47: "47",
  NUMBER_968: "968",
  NUMBER_92: "92",
  NUMBER_680: "680",
  NUMBER_970: "970",
  NUMBER_507: "507",
  NUMBER_675: "675",
  NUMBER_595: "595",
  NUMBER_51: "51",
  NUMBER_63: "63",
  NUMBER_48: "48",
  NUMBER_351: "351",
  NUMBER_1787: "1787",
  NUMBER_1939: "1939",
  NUMBER_974: "974",
  NUMBER_242: "242",
  NUMBER_40: "40",
  NUMBER_250: "250",
  NUMBER_590: "590",
  NUMBER_290: "290",
  NUMBER_1869: "1869",
  NUMBER_1758: "1758",
  NUMBER_508: "508",
  NUMBER_1784: "1784",
  NUMBER_685: "685",
  NUMBER_378: "378",
  NUMBER_239: "239",
  NUMBER_966: "966",
  NUMBER_221: "221",
  NUMBER_381: "381",
  NUMBER_248: "248",
  NUMBER_232: "232",
  NUMBER_65: "65",
  NUMBER_1721: "1721",
  NUMBER_421: "421",
  NUMBER_386: "386",
  NUMBER_677: "677",
  NUMBER_252: "252",
  NUMBER_27: "27",
  NUMBER_82: "82",
  NUMBER_211: "211",
  NUMBER_34: "34",
  NUMBER_94: "94",
  NUMBER_249: "249",
  NUMBER_597: "597",
  NUMBER_268: "268",
  NUMBER_46: "46",
  NUMBER_41: "41",
  NUMBER_963: "963",
  NUMBER_886: "886",
  NUMBER_992: "992",
  NUMBER_255: "255",
  NUMBER_66: "66",
  NUMBER_228: "228",
  NUMBER_690: "690",
  NUMBER_676: "676",
  NUMBER_1868: "1868",
  NUMBER_216: "216",
  NUMBER_90: "90",
  NUMBER_993: "993",
  NUMBER_1649: "1649",
  NUMBER_688: "688",
  NUMBER_1340: "1340",
  NUMBER_256: "256",
  NUMBER_380: "380",
  NUMBER_971: "971",
  NUMBER_44: "44",
  NUMBER_598: "598",
  NUMBER_998: "998",
  NUMBER_678: "678",
  NUMBER_379: "379",
  NUMBER_58: "58",
  NUMBER_84: "84",
  NUMBER_681: "681",
  NUMBER_967: "967",
  NUMBER_260: "260",
  NUMBER_263: "263"
}, U_ = {
  BUSINESS: "BUSINESS",
  BUSINESSSUPPLEMENTALDOCUMENTS: "BUSINESSSUPPLEMENTALDOCUMENTS",
  GOVID: "GOVID",
  ADHOCBUSINESSNAMEDOCUMENT: "ADHOCBUSINESSNAMEDOCUMENT",
  ADHOCBUSINESSADDRESSDOCUMENT: "ADHOCBUSINESSADDRESSDOCUMENT",
  ADHOCBUSINESSSTATUSDOCUMENT: "ADHOCBUSINESSSTATUSDOCUMENT",
  ADHOCHOMEADDRESSDOCUMENT: "ADHOCHOMEADDRESSDOCUMENT",
  ADHOCEINDOCUMENT: "ADHOCEINDOCUMENT",
  ADHOCIDENTITYDOCUMENT: "ADHOCIDENTITYDOCUMENT"
}, N_ = {
  merchant_cash_advance: "merchant_cash_advance",
  card: "card",
  flex_loan: "flex_loan",
  merchant_bank: "merchant_bank"
}, D_ = {
  in_progress: "in_progress",
  completed: "completed",
  failed: "failed"
}, A_ = {
  void_cash_advance: "void_cash_advance"
}, S_ = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  kyc_document_upload: "kyc_document_upload",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  bank_pad_agreement: "bank_pad_agreement",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  amazon_complete: "amazon_complete",
  advance_acceptance: "advance_acceptance",
  flex_loan_acceptance: "flex_loan_acceptance",
  offer_acceptance: "offer_acceptance",
  loan_acceptance: "loan_acceptance",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  manual_review: "manual_review",
  offer_accepted: "offer_accepted",
  offer_preview: "offer_preview",
  advance: "advance",
  flex_loan: "flex_loan",
  loan: "loan",
  capital_product: "capital_product",
  denied: "denied",
  abandoned: "abandoned",
  merchant_bank_pre_onboarding: "merchant_bank_pre_onboarding",
  merchant_bank_created: "merchant_bank_created",
  contact_verification: "contact_verification",
  start_application: "start_application"
}, G_ = {
  merchant_cash_advance: "merchant_cash_advance",
  loan: "loan",
  merchant_bank: "merchant_bank"
}, I_ = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  advance_acceptance: "advance_acceptance",
  flex_loan_acceptance: "flex_loan_acceptance",
  loan_acceptance: "loan_acceptance",
  offer_acceptance: "offer_acceptance",
  kyc_document_upload: "kyc_document_upload",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  amazon_complete: "amazon_complete",
  start_application: "start_application",
  offer_preview: "offer_preview"
}, T_ = {
  top_up_reminder: "top_up_reminder",
  fast_payouts_notification_card: "fast_payouts_notification_card"
}, w_ = {
  minimum: "minimum",
  sales_based: "sales_based"
}, Q_ = {
  unverified: "unverified",
  posted: "posted",
  reversed: "reversed",
  verified: "verified",
  failed: "failed"
}, L_ = {
  ach: "ach",
  same_day_ach: "same_day_ach",
  rtp: "rtp",
  wire: "wire"
}, W_ = {
  inner_transfer: "inner_transfer",
  card_transaction: "card_transaction",
  recipient_transfer: "recipient_transfer",
  external_bank_transfer: "external_bank_transfer",
  received_transfer: "received_transfer",
  transfer_return: "transfer_return",
  transfer_reversal: "transfer_reversal",
  repayment: "repayment"
}, z_ = {
  succeeded: "succeeded",
  failed: "failed",
  pending: "pending",
  cancelled: "cancelled",
  archived: "archived"
}, $_ = {
  money_in: "money_in",
  money_out: "money_out"
}, V_ = {
  merchant_bank_account: "merchant_bank_account",
  recipient_account: "recipient_account",
  external_bank_account: "external_bank_account"
}, H_ = {
  manual: "manual",
  payout: "payout"
}, Y_ = {
  electrical: "electrical",
  wire: "wire"
}, j_ = {
  normal: "normal",
  micro_deposit: "micro_deposit",
  partner_payout: "partner_payout"
}, X_ = {
  checking: "checking",
  savings: "savings"
}, J_ = {
  active: "active",
  unlinked: "unlinked",
  archived_due_to_update: "archived_due_to_update",
  unverified_micro_deposit: "unverified_micro_deposit",
  failed_micro_deposit: "failed_micro_deposit"
}, Z_ = {
  physical: "physical",
  virtual: "virtual"
}, tp = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, ep = {
  cash: "cash",
  credit: "credit"
}, np = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, rp = {
  did_not_purchase: "did_not_purchase",
  charged_for_cancelled_or_returned_purchase: "charged_for_cancelled_or_returned_purchase",
  incorrect_amount: "incorrect_amount",
  never_received: "never_received",
  expecting_credit: "expecting_credit",
  not_as_described_or_defective: "not_as_described_or_defective",
  other: "other"
}, sp = {
  contact_verification: "contact_verification",
  kyc: "kyc",
  kyc_document_upload: "kyc_document_upload",
  kyc_submitted: "kyc_submitted",
  kyc_approved: "kyc_approved",
  manual_review: "manual_review",
  merchant_bank_created: "merchant_bank_created",
  denied: "denied"
}, ap = {
  checking: "checking",
  savings: "savings"
}, op = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, up = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  statement_upload: "statement_upload",
  plaid_investigation: "plaid_investigation",
  manual_review: "manual_review",
  liens_pending: "liens_pending"
}, ip = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, cp = {
  ucc: "ucc",
  federal: "federal",
  state: "state"
}, yp = {
  open: "open",
  closed: "closed",
  unknown: "unknown"
}, dp = {
  name: "name",
  address: "address",
  watchlist: "watchlist",
  ein: "ein",
  id: "id",
  date_of_birth: "date_of_birth",
  date_of_birth_miskey: "date_of_birth_miskey",
  ssn_miskey: "ssn_miskey",
  other_warning: "other_warning"
}, _p = {
  alloy: "alloy",
  marqeta: "marqeta",
  middesk: "middesk",
  persona: "persona",
  sandbox: "sandbox",
  unplugged: "unplugged"
}, pp = {
  checking_to_savings: "checking_to_savings",
  savings_to_checking: "savings_to_checking"
}, lp = {
  not_started: "not_started",
  pending: "pending",
  success: "success",
  failed: "failed"
}, mp = {
  irrelevant: "irrelevant",
  incomplete: "incomplete",
  complete: "complete"
}, gp = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, qp = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, fp = {
  all_merchants: "all_merchants",
  post_opt_in: "post_opt_in",
  post_opt_in_sync_webhook: "post_opt_in_sync_webhook",
  never: "never"
}, Op = {
  upcoming_repayment: "upcoming_repayment",
  scheduled_repayment: "scheduled_repayment"
}, hp = {
  manual: "manual",
  rutter: "rutter"
}, Kp = {
  string: "string",
  decimal: "decimal",
  int: "int",
  date: "date",
  object: "object",
  currency_code: "currency_code",
  boolean: "boolean"
}, bp = {
  business: "business",
  account: "account"
}, kp = {
  BUSINESS_GROUP_ADMIN: "BUSINESS GROUP ADMIN",
  ADMIN: "ADMIN",
  "N/A": "N/A",
  MANAGER: "MANAGER",
  STORE_OPERATOR: "STORE OPERATOR"
}, Mp = {
  kyc: "kyc",
  bank_verification: "bank_verification",
  financial_review: "financial_review",
  tax_record: "tax_record"
}, Pp = {
  apply: "apply",
  lift: "lift"
}, xp = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  location: "location",
  program_terminated: "program_terminated",
  no_amazon_offer: "no_amazon_offer"
}, Bp = {
  file_feed: "file_feed",
  manual_upload: "manual_upload",
  standard_s3: "standard_s3",
  api: "api",
  custom_legacy: "custom_legacy",
  opt_in: "opt_in"
}, Rp = {
  pending: "pending",
  success: "success",
  failed: "failed"
}, Fp = {
  business: "business",
  sale_record: "sale_record",
  bank_account_info: "bank_account_info",
  person: "person",
  person_business_relationship: "person_business_relationship"
}, Ep = {
  irrelevant: "irrelevant",
  optional: "optional",
  required: "required"
}, Cp = {
  UNSUPPORTEDCURRENCY: "UNSUPPORTEDCURRENCY",
  USD: "USD",
  CAD: "CAD",
  USDFOURTHDECIMAL: "USDFOURTHDECIMAL"
}, vp = {
  hubspot: "hubspot",
  marketo: "marketo",
  salesforce: "salesforce"
}, Up = {
  offer_summary: "offer_summary"
}, Np = {
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed"
}, Dp = {
  US: "US",
  CA: "CA"
}, Ap = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Sp = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Gp = {
  fulfillmentdigitally_presented: "fulfillment.digitally_presented",
  fulfillmentissued: "fulfillment.issued",
  fulfillmentordered: "fulfillment.ordered",
  fulfillmentrejected: "fulfillment.rejected",
  fulfillmentreordered: "fulfillment.reordered",
  fulfillmentshipped: "fulfillment.shipped",
  stateactivated: "state.activated",
  statereinstated: "state.reinstated",
  statesuspended: "state.suspended",
  stateterminated: "state.terminated",
  pinchanged: "pin.changed",
  pinset: "pin.set",
  pinreveal: "pin.reveal"
}, Ip = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNACTIVATED: "UNACTIVATED"
}, Tp = {
  NUMBER_00: "00",
  NUMBER_01: "01",
  NUMBER_02: "02",
  NUMBER_03: "03",
  NUMBER_04: "04",
  NUMBER_05: "05",
  NUMBER_06: "06",
  NUMBER_07: "07",
  NUMBER_08: "08",
  NUMBER_09: "09",
  NUMBER_10: "10",
  NUMBER_11: "11",
  NUMBER_12: "12",
  NUMBER_13: "13",
  NUMBER_14: "14",
  NUMBER_15: "15",
  NUMBER_16: "16",
  NUMBER_17: "17",
  NUMBER_18: "18",
  NUMBER_19: "19",
  NUMBER_20: "20",
  NUMBER_21: "21",
  NUMBER_22: "22",
  NUMBER_23: "23",
  NUMBER_24: "24",
  NUMBER_25: "25",
  NUMBER_26: "26",
  NUMBER_27: "27",
  NUMBER_28: "28",
  NUMBER_29: "29",
  NUMBER_30: "30",
  NUMBER_31: "31"
}, wp = {
  ADMIN: "ADMIN",
  API: "API",
  FRAUD: "FRAUD",
  IVR: "IVR",
  SYSTEM: "SYSTEM"
}, Qp = {
  LOCAL_MAIL: "LOCAL_MAIL",
  GROUND: "GROUND",
  TWO_DAY: "TWO_DAY",
  OVERNIGHT: "OVERNIGHT",
  INTERNATIONAL: "INTERNATIONAL",
  FEDEX_EXPEDITED: "FEDEX_EXPEDITED",
  FEDEX_REGULAR: "FEDEX_REGULAR",
  UPS_EXPEDITED: "UPS_EXPEDITED",
  UPS_REGULAR: "UPS_REGULAR",
  USPS_EXPEDITED: "USPS_EXPEDITED",
  USPS_REGULAR: "USPS_REGULAR"
}, Lp = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Wp = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, zp = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, $p = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Vp = {
  physical: "physical",
  virtual: "virtual"
}, Hp = {
  physical: "physical",
  virtual: "virtual"
}, Yp = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, jp = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan"
}, Xp = {
  outstanding: "outstanding",
  paid: "paid",
  abandoned: "abandoned",
  cancelled: "cancelled",
  waived: "waived",
  void: "void"
}, Jp = {
  "1month": "1month",
  "2month": "2month",
  "3month": "3month",
  "4month": "4month",
  "5month": "5month",
  "6month": "6month",
  "7month": "7month",
  "8month": "8month",
  "9month": "9month",
  "10month": "10month",
  "11month": "11month",
  "12month": "12month",
  "13month": "13month",
  "14month": "14month",
  "15month": "15month",
  "16month": "16month",
  "17month": "17month",
  "18month": "18month",
  "19month": "19month",
  "20month": "20month",
  "21month": "21month",
  "22month": "22month",
  "23month": "23month",
  "24month": "24month"
}, Zp = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, tl = {
  healthy: "healthy",
  unhealthy: "unhealthy"
}, el = {
  merchant_cash_advance: "merchant_cash_advance",
  amazon_mca: "amazon_mca",
  flex_loan: "flex_loan",
  term_loan: "term_loan"
}, nl = {
  in_progress: "in_progress",
  manual_review: "manual_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  cancelled: "cancelled",
  withdrawn: "withdrawn",
  offer_accepted: "offer_accepted",
  funded: "funded"
}, rl = {
  principal: "principal",
  fee: "fee",
  payment: "payment",
  minimum_payment: "minimum_payment",
  automatic_payment: "automatic_payment",
  manual_payment: "manual_payment",
  generic: "generic",
  payment_adjustment: "payment_adjustment"
}, sl = {
  card: "card",
  cash_advance: "cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  merchant_bank: "merchant_bank"
}, al = {
  full: "full",
  partial: "partial",
  none: "none",
  partner_does_not_have: "partner_does_not_have"
}, ol = {
  bankruptcy: "bankruptcy",
  excessive_credit_obligations: "excessive_credit_obligations",
  fraud: "fraud",
  insufficient_sales: "insufficient_sales",
  recent_sales_volatility: "recent_sales_volatility",
  liens: "liens",
  problematic_bank_account: "problematic_bank_account",
  problematic_payment_history: "problematic_payment_history",
  too_many_liens: "too_many_liens",
  unpaid_prior_account: "unpaid_prior_account",
  unserviceable_bank_account: "unserviceable_bank_account",
  kyc: "kyc",
  unlicensed_business: "unlicensed_business",
  program_terminated: "program_terminated",
  "": ""
}, ul = {
  HOME: "HOME",
  WORK: "WORK",
  LEGAL: "LEGAL",
  SHIPPING: "SHIPPING",
  PHYSICAL: "PHYSICAL"
}, il = {
  celtic_bank: "celtic_bank",
  parafin: "parafin",
  parafin_spv_1: "parafin_spv_1",
  parafin_spv_2: "parafin_spv_2",
  parafin_spv_3: "parafin_spv_3"
}, d = (t, e) => o.get(
  "/business_cores",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _ = (t) => ["/business_cores", ...t ? [t] : []], p = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _(t), queryFn: ({ signal: u }) => d(t, { signal: u, ...r }), ...n };
};
function cl(t, e) {
  const n = p(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const l = (t, e) => o.post(
  "/business_cores",
  t,
  e
), m = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return l(a, n);
  }, ...e };
}, yl = (t) => {
  const e = m(t);
  return c(e);
}, g = (t, e) => o.get(
  "/business_annotations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), q = (t) => ["/business_annotations", ...t ? [t] : []], f = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? q(t), queryFn: ({ signal: u }) => g(t, { signal: u, ...r }), ...n };
};
function dl(t, e) {
  const n = f(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const O = (t, e) => o.post(
  "/business_annotations",
  t,
  e
), h = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return O(a, n);
  }, ...e };
}, _l = (t) => {
  const e = h(t);
  return c(e);
}, K = (t, e) => o.get(
  "/cash_advance_charge_offs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), b = (t) => ["/cash_advance_charge_offs", ...t ? [t] : []], k = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? b(t), queryFn: ({ signal: u }) => K(t, { signal: u, ...r }), ...n };
};
function pl(t, e) {
  const n = k(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const M = (t, e) => o.post(
  "/cash_advance_charge_offs",
  t,
  e
), P = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return M(a, n);
  }, ...e };
}, ll = (t) => {
  const e = P(t);
  return c(e);
}, x = (t, e) => o.get(
  "/partners/doordash/admin_users",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), B = (t) => ["/partners/doordash/admin_users", ...t ? [t] : []], R = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? B(t), queryFn: ({ signal: u }) => x(t, { signal: u, ...r }), ...n };
};
function ml(t, e) {
  const n = R(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const F = (t, e) => o.get(
  "/cash_advance_pauses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), E = (t) => ["/cash_advance_pauses", ...t ? [t] : []], C = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? E(t), queryFn: ({ signal: u }) => F(t, { signal: u, ...r }), ...n };
};
function gl(t, e) {
  const n = C(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const v = (t, e) => o.post(
  "/cash_advance_pauses",
  t,
  e
), U = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return v(a, n);
  }, ...e };
}, ql = (t) => {
  const e = U(t);
  return c(e);
}, N = (t, e) => o.delete(
  `/cash_advance_pauses/${t}`,
  e
), D = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a } = s ?? {};
    return N(a, n);
  }, ...e };
}, fl = (t) => {
  const e = D(t);
  return c(e);
}, A = (t, e, n) => o.patch(
  `/cash_advance_pauses/${t}`,
  e,
  n
), S = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a, data: u } = s ?? {};
    return A(a, u, n);
  }, ...e };
}, Ol = (t) => {
  const e = S(t);
  return c(e);
}, G = (t, e) => o.get(
  "/repayment_plan_parameters",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), I = (t) => ["/repayment_plan_parameters", ...t ? [t] : []], T = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? I(t), queryFn: ({ signal: u }) => G(t, { signal: u, ...r }), ...n };
};
function hl(t, e) {
  const n = T(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const w = (t, e) => o.get(
  "/future_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Q = (t) => ["/future_activities", ...t ? [t] : []], L = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Q(t), queryFn: ({ signal: u }) => w(t, { signal: u, ...r }), ...n };
};
function Kl(t, e) {
  const n = L(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const W = (t, e) => o.get(
  "/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), z = (t) => ["/partners", ...t ? [t] : []], $ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? z(t), queryFn: ({ signal: u }) => W(t, { signal: u, ...r }), ...n };
};
function bl(t, e) {
  const n = $(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const V = (t, e) => o.post(
  "/partners",
  t,
  e
), H = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return V(a, n);
  }, ...e };
}, kl = (t) => {
  const e = H(t);
  return c(e);
}, Y = (t) => o.get(
  "/partners/underwriting",
  t
), j = () => ["/partners/underwriting"], X = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? j(), queryFn: ({ signal: a }) => Y({ signal: a, ...n }), ...e };
};
function Ml(t) {
  const e = X(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const J = (t, e, n) => o.patch(
  `/partners/${t}`,
  e,
  n
), Z = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return J(a, u, n);
  }, ...e };
}, Pl = (t) => {
  const e = Z(t);
  return c(e);
}, tt = (t, e) => o.get(
  "/partner/api_keys",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), et = (t) => ["/partner/api_keys", ...t ? [t] : []], nt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? et(t), queryFn: ({ signal: u }) => tt(t, { signal: u, ...r }), ...n };
};
function xl(t, e) {
  const n = nt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rt = (t, e) => o.post(
  "/partner/api_keys",
  t,
  e
), st = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return rt(a, n);
  }, ...e };
}, Bl = (t) => {
  const e = st(t);
  return c(e);
}, at = (t) => o.get(
  "/partner/organizations",
  t
), ot = () => ["/partner/organizations"], ut = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ot(), queryFn: ({ signal: a }) => at({ signal: a, ...n }), ...e };
};
function Rl(t) {
  const e = ut(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const it = (t, e) => o.post(
  "/partner/organizations",
  t,
  e
), ct = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return it(a, n);
  }, ...e };
}, Fl = (t) => {
  const e = ct(t);
  return c(e);
}, yt = (t, e) => o.post(
  "/partner/organizations/link_partner",
  t,
  e
), dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return yt(a, n);
  }, ...e };
}, El = (t) => {
  const e = dt(t);
  return c(e);
}, _t = (t, e) => o.get(
  "/partner/organizations/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pt = (t) => ["/partner/organizations/partners", ...t ? [t] : []], lt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pt(t), queryFn: ({ signal: u }) => _t(t, { signal: u, ...r }), ...n };
};
function Cl(t, e) {
  const n = lt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const mt = (t, e) => o.post(
  "/slack/authorize",
  t,
  e
), gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return mt(a, n);
  }, ...e };
}, vl = (t) => {
  const e = gt(t);
  return c(e);
}, qt = (t) => o.get(
  "/slack/channels",
  t
), ft = () => ["/slack/channels"], Ot = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ft(), queryFn: ({ signal: a }) => qt({ signal: a, ...n }), ...e };
};
function Ul(t) {
  const e = Ot(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ht = (t, e) => o.delete(
  `/slack/channels/${t}`,
  e
), Kt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return ht(a, n);
  }, ...e };
}, Nl = (t) => {
  const e = Kt(t);
  return c(e);
}, bt = (t) => o.get(
  "/partner/users",
  t
), kt = () => ["/partner/users"], Mt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? kt(), queryFn: ({ signal: a }) => bt({ signal: a, ...n }), ...e };
};
function Dl(t) {
  const e = Mt(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Pt = (t, e) => o.post(
  "/partner/users",
  t,
  e
), xt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Pt(a, n);
  }, ...e };
}, Al = (t) => {
  const e = xt(t);
  return c(e);
}, Bt = (t, e) => o.patch(
  "/partner/users",
  t,
  e
), Rt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bt(a, n);
  }, ...e };
}, Sl = (t) => {
  const e = Rt(t);
  return c(e);
}, Ft = (t, e) => o.delete(
  "/partner/delete_users",
  { data: t, ...e }
), Et = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ft(a, n);
  }, ...e };
}, Gl = (t) => {
  const e = Et(t);
  return c(e);
}, Ct = (t, e) => o.get(
  "/partner/public_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vt = (t) => ["/partner/public_configs", ...t ? [t] : []], Ut = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vt(t), queryFn: ({ signal: u }) => Ct(t, { signal: u, ...r }), ...n };
};
function Il(t, e) {
  const n = Ut(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Nt = (t, e) => o.get(
  "/partner/configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dt = (t) => ["/partner/configs", ...t ? [t] : []], At = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dt(t), queryFn: ({ signal: u }) => Nt(t, { signal: u, ...r }), ...n };
};
function Tl(t, e) {
  const n = At(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const St = (t, e, n) => o.patch(
  `/partner/configs/${t}`,
  e,
  n
), Gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return St(a, u, n);
  }, ...e };
}, wl = (t) => {
  const e = Gt(t);
  return c(e);
}, It = (t) => o.get(
  "/partner/sync_webhooks",
  t
), Tt = () => ["/partner/sync_webhooks"], wt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Tt(), queryFn: ({ signal: a }) => It({ signal: a, ...n }), ...e };
};
function Ql(t) {
  const e = wt(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Qt = (t, e) => o.post(
  "/partner/sync_webhooks",
  t,
  e
), Lt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qt(a, n);
  }, ...e };
}, Ll = (t) => {
  const e = Lt(t);
  return c(e);
}, Wt = (t, e) => o.delete(
  `/partner/sync_webhooks/${t}`,
  e
), zt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Wt(a, n);
  }, ...e };
}, Wl = (t) => {
  const e = zt(t);
  return c(e);
}, $t = (t, e, n) => o.patch(
  `/partner/sync_webhooks/${t}`,
  e,
  n
), Vt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return $t(a, u, n);
  }, ...e };
}, zl = (t) => {
  const e = Vt(t);
  return c(e);
}, Ht = (t, e) => o.post(
  "/auth/tokens",
  t,
  e
), Yt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ht(a, n);
  }, ...e };
}, $l = (t) => {
  const e = Yt(t);
  return c(e);
}, jt = (t, e) => o.get(
  "/statements_v2",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xt = (t) => ["/statements_v2", ...t ? [t] : []], Jt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xt(t), queryFn: ({ signal: u }) => jt(t, { signal: u, ...r }), ...n };
};
function Vl(t, e) {
  const n = Jt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zt = (t, e) => o.post(
  "/marketing/review",
  t,
  e
), te = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zt(a, n);
  }, ...e };
}, Hl = (t) => {
  const e = te(t);
  return c(e);
}, ee = (t) => o.get(
  "/marketing/integrations",
  t
), ne = () => ["/marketing/integrations"], re = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ne(), queryFn: ({ signal: a }) => ee({ signal: a, ...n }), ...e };
};
function Yl(t) {
  const e = re(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const se = (t, e) => o.post(
  "/marketing/integrations",
  t,
  e
), ae = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return se(a, n);
  }, ...e };
}, jl = (t) => {
  const e = ae(t);
  return c(e);
}, oe = (t, e) => o.delete(
  `/marketing/integrations/${t}`,
  e
), ue = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { vendor: a } = s ?? {};
    return oe(a, n);
  }, ...e };
}, Xl = (t) => {
  const e = ue(t);
  return c(e);
}, ie = (t) => o.get(
  "/marketing/gtm_toolkit",
  t
), ce = () => ["/marketing/gtm_toolkit"], ye = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ce(), queryFn: ({ signal: a }) => ie({ signal: a, ...n }), ...e };
};
function Jl(t) {
  const e = ye(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const de = (t) => o.get(
  "/marketing/paragon_token",
  t
), _e = () => ["/marketing/paragon_token"], pe = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? _e(), queryFn: ({ signal: a }) => de({ signal: a, ...n }), ...e };
};
function Zl(t) {
  const e = pe(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const le = (t, e) => o.get(
  "/beneficial_owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), me = (t) => ["/beneficial_owners", ...t ? [t] : []], ge = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? me(t), queryFn: ({ signal: u }) => le(t, { signal: u, ...r }), ...n };
};
function tm(t, e) {
  const n = ge(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qe = (t, e) => o.post(
  "/beneficial_owners",
  t,
  e
), fe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qe(a, n);
  }, ...e };
}, em = (t) => {
  const e = fe(t);
  return c(e);
}, Oe = (t, e) => o.delete(
  `/beneficial_owners/${t}`,
  e
), he = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Oe(a, n);
  }, ...e };
}, nm = (t) => {
  const e = he(t);
  return c(e);
}, Ke = (t, e, n) => o.patch(
  `/beneficial_owners/${t}`,
  e,
  n
), be = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ke(a, u, n);
  }, ...e };
}, rm = (t) => {
  const e = be(t);
  return c(e);
}, ke = (t, e) => o.get(
  "/owner_is_beneficial_owner",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Me = (t) => ["/owner_is_beneficial_owner", ...t ? [t] : []], Pe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Me(t), queryFn: ({ signal: u }) => ke(t, { signal: u, ...r }), ...n };
};
function sm(t, e) {
  const n = Pe(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xe = (t, e) => o.patch(
  "/owner_is_beneficial_owner",
  t,
  e
), Be = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xe(a, n);
  }, ...e };
}, am = (t) => {
  const e = Be(t);
  return c(e);
}, Re = (t, e) => o.get(
  "/banks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fe = (t) => ["/banks", ...t ? [t] : []], Ee = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fe(t), queryFn: ({ signal: u }) => Re(t, { signal: u, ...r }), ...n };
};
function om(t, e) {
  const n = Ee(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ce = (t, e) => o.get(
  "/business_table_rows/product_agnostic",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ve = (t) => ["/business_table_rows/product_agnostic", ...t ? [t] : []], Ue = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ve(t), queryFn: ({ signal: u }) => Ce(t, { signal: u, ...r }), ...n };
};
function um(t, e) {
  const n = Ue(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ne = (t, e) => o.get(
  "/business_table_rows/mca",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), De = (t) => ["/business_table_rows/mca", ...t ? [t] : []], Ae = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? De(t), queryFn: ({ signal: u }) => Ne(t, { signal: u, ...r }), ...n };
};
function im(t, e) {
  const n = Ae(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Se = (t, e) => o.get(
  "/business_table_rows/loan",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ge = (t) => ["/business_table_rows/loan", ...t ? [t] : []], Ie = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ge(t), queryFn: ({ signal: u }) => Se(t, { signal: u, ...r }), ...n };
};
function cm(t, e) {
  const n = Ie(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Te = (t, e) => o.get(
  "/business_table_rows/card",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), we = (t) => ["/business_table_rows/card", ...t ? [t] : []], Qe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? we(t), queryFn: ({ signal: u }) => Te(t, { signal: u, ...r }), ...n };
};
function ym(t, e) {
  const n = Qe(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Le = (t, e) => o.get(
  "/business/officers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), We = (t) => ["/business/officers", ...t ? [t] : []], ze = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? We(t), queryFn: ({ signal: u }) => Le(t, { signal: u, ...r }), ...n };
};
function dm(t, e) {
  const n = ze(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $e = (t, e) => o.post(
  "/business/officers",
  t,
  e
), Ve = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $e(a, n);
  }, ...e };
}, _m = (t) => {
  const e = Ve(t);
  return c(e);
}, He = (t, e) => o.delete(
  `/business/officers/${t}`,
  e
), Ye = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return He(a, n);
  }, ...e };
}, pm = (t) => {
  const e = Ye(t);
  return c(e);
}, je = (t, e, n) => o.patch(
  `/business/officers/${t}`,
  e,
  n
), Xe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return je(a, u, n);
  }, ...e };
}, lm = (t) => {
  const e = Xe(t);
  return c(e);
}, Je = (t) => o.post(
  "/dataingestion/oneschema/generate_jwt",
  void 0,
  t
), Ze = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Je(n), ...e };
}, mm = (t) => {
  const e = Ze(t);
  return c(e);
}, tn = (t, e) => o.get(
  "/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), en = (t) => ["/dataset_uploads", ...t ? [t] : []], nn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? en(t), queryFn: ({ signal: u }) => tn(t, { signal: u, ...r }), ...n };
};
function gm(t, e) {
  const n = nn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rn = (t, e) => o.get(
  "/dataingestion/manual_uploads/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sn = (t) => ["/dataingestion/manual_uploads/dataset_upload_validation_results", ...t ? [t] : []], an = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sn(t), queryFn: ({ signal: u }) => rn(t, { signal: u, ...r }), ...n };
};
function qm(t, e) {
  const n = an(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const on = (t, e) => o.get(
  "/dataingestion/s3/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), un = (t) => ["/dataingestion/s3/dataset_uploads", ...t ? [t] : []], cn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? un(t), queryFn: ({ signal: u }) => on(t, { signal: u, ...r }), ...n };
};
function fm(t, e) {
  const n = cn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const yn = (t, e) => o.get(
  "/dataingestion/s3/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dn = (t) => ["/dataingestion/s3/dataset_upload_validation_results", ...t ? [t] : []], _n = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dn(t), queryFn: ({ signal: u }) => yn(t, { signal: u, ...r }), ...n };
};
function Om(t, e) {
  const n = _n(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const pn = (t, e) => o.get(
  "/dataingestion/s3/dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ln = (t) => ["/dataingestion/s3/dataset_integrations", ...t ? [t] : []], mn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ln(t), queryFn: ({ signal: u }) => pn(t, { signal: u, ...r }), ...n };
};
function hm(t, e) {
  const n = mn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gn = (t, e) => o.post(
  "/dataingestion/s3/dataset_integrations",
  t,
  e
), qn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return gn(a, n);
  }, ...e };
}, Km = (t) => {
  const e = qn(t);
  return c(e);
}, fn = (t, e) => o.get(
  "/dataingestion/s3/org_dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), On = (t) => ["/dataingestion/s3/org_dataset_integrations", ...t ? [t] : []], hn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? On(t), queryFn: ({ signal: u }) => fn(t, { signal: u, ...r }), ...n };
};
function bm(t, e) {
  const n = hn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Kn = (t, e) => o.post(
  "/dataingestion/s3/org_dataset_integrations",
  t,
  e
), bn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Kn(a, n);
  }, ...e };
}, km = (t) => {
  const e = bn(t);
  return c(e);
}, kn = (t, e) => o.get(
  "/dataingestion/s3/dataset_ingestion_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Mn = (t) => ["/dataingestion/s3/dataset_ingestion_configs", ...t ? [t] : []], Pn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mn(t), queryFn: ({ signal: u }) => kn(t, { signal: u, ...r }), ...n };
};
function Mm(t, e) {
  const n = Pn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xn = (t, e) => o.put(
  "/dataingestion/s3/dataset_ingestion_configs",
  t,
  e
), Bn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xn(a, n);
  }, ...e };
}, Pm = (t) => {
  const e = Bn(t);
  return c(e);
}, Rn = (t, e) => o.post(
  "/dataingestion/s3/run_dataset_ingestion",
  t,
  e
), Fn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Rn(a, n);
  }, ...e };
}, xm = (t) => {
  const e = Fn(t);
  return c(e);
}, En = (t, e) => o.get(
  "/dataingestion/schema",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cn = (t) => ["/dataingestion/schema", ...t ? [t] : []], vn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cn(t), queryFn: ({ signal: u }) => En(t, { signal: u, ...r }), ...n };
};
function Bm(t, e) {
  const n = vn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Un = (t, e) => o.get(
  "/dataingestion/stripe_ingestions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Nn = (t) => ["/dataingestion/stripe_ingestions", ...t ? [t] : []], Dn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Nn(t), queryFn: ({ signal: u }) => Un(t, { signal: u, ...r }), ...n };
};
function Rm(t, e) {
  const n = Dn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const An = (t, e) => o.post(
  "/dataingestion/stripe_ingestions",
  t,
  e
), Sn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return An(a, n);
  }, ...e };
}, Fm = (t) => {
  const e = Sn(t);
  return c(e);
}, Gn = (t, e) => o.patch(
  "/dataingestion/stripe_ingestions",
  t,
  e
), In = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gn(a, n);
  }, ...e };
}, Em = (t) => {
  const e = In(t);
  return c(e);
}, Tn = (t, e) => o.get(
  "/onboarding/business_requirements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wn = (t) => ["/onboarding/business_requirements", ...t ? [t] : []], Qn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wn(t), queryFn: ({ signal: u }) => Tn(t, { signal: u, ...r }), ...n };
};
function Cm(t, e) {
  const n = Qn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ln = (t, e) => o.get(
  "/capital_product_prospective_terms_of_service",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wn = (t) => ["/capital_product_prospective_terms_of_service", ...t ? [t] : []], zn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wn(t), queryFn: ({ signal: u }) => Ln(t, { signal: u, ...r }), ...n };
};
function vm(t, e) {
  const n = zn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $n = (t, e) => o.get(
  "/capital_products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vn = (t) => ["/capital_products", ...t ? [t] : []], Hn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vn(t), queryFn: ({ signal: u }) => $n(t, { signal: u, ...r }), ...n };
};
function Um(t, e) {
  const n = Hn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Yn = (t, e) => o.post(
  "/capital_products",
  t,
  e
), jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yn(a, n);
  }, ...e };
}, Nm = (t) => {
  const e = jn(t);
  return c(e);
}, Xn = (t, e, n) => o.patch(
  `/capital_products/${t}`,
  e,
  n
), Jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Xn(a, u, n);
  }, ...e };
}, Dm = (t) => {
  const e = Jn(t);
  return c(e);
}, Zn = (t, e) => o.get(
  "/capital_product_offer_collections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tr = (t) => ["/capital_product_offer_collections", ...t ? [t] : []], er = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tr(t), queryFn: ({ signal: u }) => Zn(t, { signal: u, ...r }), ...n };
};
function Am(t, e) {
  const n = er(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const nr = (t, e) => o.post(
  "/capital_product_offer_collections",
  t,
  e
), rr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return nr(a, n);
  }, ...e };
}, Sm = (t) => {
  const e = rr(t);
  return c(e);
}, sr = (t, e) => o.get(
  "/capital_product_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ar = (t) => ["/capital_product_activities", ...t ? [t] : []], or = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ar(t), queryFn: ({ signal: u }) => sr(t, { signal: u, ...r }), ...n };
};
function Gm(t, e) {
  const n = or(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ur = (t, e) => o.post(
  "/capital_product_applications",
  t,
  e
), ir = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ur(a, n);
  }, ...e };
}, Im = (t) => {
  const e = ir(t);
  return c(e);
}, cr = (t, e) => o.post(
  "/net_top_up",
  t,
  e
), yr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return cr(a, n);
  }, ...e };
}, Tm = (t) => {
  const e = yr(t);
  return c(e);
}, dr = (t, e) => o.get(
  "/capital_product_net_top_up_itemization",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _r = (t) => ["/capital_product_net_top_up_itemization", ...t ? [t] : []], pr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _r(t), queryFn: ({ signal: u }) => dr(t, { signal: u, ...r }), ...n };
};
function wm(t, e) {
  const n = pr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const lr = (t, e) => o.get(
  "/capital_product_state_disclosure",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mr = (t) => ["/capital_product_state_disclosure", ...t ? [t] : []], gr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mr(t), queryFn: ({ signal: u }) => lr(t, { signal: u, ...r }), ...n };
};
function Qm(t, e) {
  const n = gr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qr = (t) => o.get(
  "/benchmark_capital_products_ownerships",
  t
), fr = () => ["/benchmark_capital_products_ownerships"], Or = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? fr(), queryFn: ({ signal: a }) => qr({ signal: a, ...n }), ...e };
};
function Lm(t) {
  const e = Or(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const hr = (t, e) => o.get(
  "/capital_product_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kr = (t) => ["/capital_product_repayment_schedule", ...t ? [t] : []], br = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kr(t), queryFn: ({ signal: u }) => hr(t, { signal: u, ...r }), ...n };
};
function Wm(t, e) {
  const n = br(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const kr = (t, e) => o.post(
  "/offered_capital_product_discount",
  t,
  e
), Mr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return kr(a, n);
  }, ...e };
}, zm = (t) => {
  const e = Mr(t);
  return c(e);
}, Pr = (t, e) => o.get(
  "/capital_product_intents",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xr = (t) => ["/capital_product_intents", ...t ? [t] : []], Br = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xr(t), queryFn: ({ signal: u }) => Pr(t, { signal: u, ...r }), ...n };
};
function $m(t, e) {
  const n = Br(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Rr = (t, e) => o.post(
  "/capital_product_intents",
  t,
  e
), Fr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Rr(a, n);
  }, ...e };
}, Vm = (t) => {
  const e = Fr(t);
  return c(e);
}, Er = (t, e, n) => o.patch(
  `/capital_product_intents/${t}`,
  e,
  n
), Cr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Er(a, u, n);
  }, ...e };
}, Hm = (t) => {
  const e = Cr(t);
  return c(e);
}, vr = (t, e) => o.get(
  "/counteroffer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ur = (t) => ["/counteroffer", ...t ? [t] : []], Nr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ur(t), queryFn: ({ signal: u }) => vr(t, { signal: u, ...r }), ...n };
};
function Ym(t, e) {
  const n = Nr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Dr = (t, e) => o.get(
  "/flex_loan_minimum_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ar = (t) => ["/flex_loan_minimum_repayment_schedule", ...t ? [t] : []], Sr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ar(t), queryFn: ({ signal: u }) => Dr(t, { signal: u, ...r }), ...n };
};
function jm(t, e) {
  const n = Sr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Gr = (t, e) => o.post(
  "/trigger_run_celtics_checklist",
  t,
  e
), Ir = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gr(a, n);
  }, ...e };
}, Xm = (t) => {
  const e = Ir(t);
  return c(e);
}, Tr = (t, e) => o.get(
  "/run_ofac_screening_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wr = (t) => ["/run_ofac_screening_results", ...t ? [t] : []], Qr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wr(t), queryFn: ({ signal: u }) => Tr(t, { signal: u, ...r }), ...n };
};
function Jm(t, e) {
  const n = Qr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Lr = (t, e, n) => o.patch(
  `/clear_watchlist_hits/${t}`,
  e,
  n
), Wr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Lr(a, u, n);
  }, ...e };
}, Zm = (t) => {
  const e = Wr(t);
  return c(e);
}, zr = (t, e) => o.get(
  "/term_loan_prospective_repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $r = (t) => ["/term_loan_prospective_repayments", ...t ? [t] : []], Vr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $r(t), queryFn: ({ signal: u }) => zr(t, { signal: u, ...r }), ...n };
};
function tg(t, e) {
  const n = Vr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Hr = (t, e) => o.get(
  "/funding/bank_account_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yr = (t) => ["/funding/bank_account_info", ...t ? [t] : []], jr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yr(t), queryFn: ({ signal: u }) => Hr(t, { signal: u, ...r }), ...n };
};
function eg(t, e) {
  const n = jr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Xr = (t, e) => o.post(
  "/funding/bank_account_info",
  t,
  e
), Jr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Xr(a, n);
  }, ...e };
}, ng = (t) => {
  const e = Jr(t);
  return c(e);
}, Zr = (t, e, n) => o.patch(
  `/funding/bank_account_info/${t}`,
  e,
  n
), ts = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Zr(a, u, n);
  }, ...e };
}, rg = (t) => {
  const e = ts(t);
  return c(e);
}, es = (t, e) => o.get(
  "/funding/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ns = (t) => ["/funding/accounts", ...t ? [t] : []], rs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ns(t), queryFn: ({ signal: u }) => es(t, { signal: u, ...r }), ...n };
};
function sg(t, e) {
  const n = rs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ss = (t, e) => o.post(
  "/funding/accounts",
  t,
  e
), as = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ss(a, n);
  }, ...e };
}, ag = (t) => {
  const e = as(t);
  return c(e);
}, os = (t, e) => o.get(
  "/funding/plaid_linkage_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), us = (t) => ["/funding/plaid_linkage_with_investigation", ...t ? [t] : []], is = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? us(t), queryFn: ({ signal: u }) => os(t, { signal: u, ...r }), ...n };
};
function og(t, e) {
  const n = is(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const cs = (t, e) => o.post(
  "/funding/plaid_linkage_with_investigation",
  t,
  e
), ys = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return cs(a, n);
  }, ...e };
}, ug = (t) => {
  const e = ys(t);
  return c(e);
}, ds = (t, e, n) => o.patch(
  `/persona_fallback_inquiries/${t}`,
  e,
  n
), _s = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return ds(a, u, n);
  }, ...e };
}, ig = (t) => {
  const e = _s(t);
  return c(e);
}, ps = (t, e) => o.get(
  "/onboarding/states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ls = (t) => ["/onboarding/states", ...t ? [t] : []], ms = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ls(t), queryFn: ({ signal: u }) => ps(t, { signal: u, ...r }), ...n };
};
function cg(t, e) {
  const n = ms(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gs = (t, e) => o.get(
  "/onboarding/info_needed",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qs = (t) => ["/onboarding/info_needed", ...t ? [t] : []], fs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qs(t), queryFn: ({ signal: u }) => gs(t, { signal: u, ...r }), ...n };
};
function yg(t, e) {
  const n = fs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Os = (t, e) => o.get(
  "/business_identities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hs = (t) => ["/business_identities", ...t ? [t] : []], Ks = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hs(t), queryFn: ({ signal: u }) => Os(t, { signal: u, ...r }), ...n };
};
function dg(t, e) {
  const n = Ks(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const bs = (t, e, n) => o.patch(
  `/business_identities/${t}`,
  e,
  n
), ks = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return bs(a, u, n);
  }, ...e };
}, _g = (t) => {
  const e = ks(t);
  return c(e);
}, Ms = (t, e) => o.get(
  "/owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ps = (t) => ["/owners", ...t ? [t] : []], xs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ps(t), queryFn: ({ signal: u }) => Ms(t, { signal: u, ...r }), ...n };
};
function pg(t, e) {
  const n = xs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Bs = (t, e, n) => o.patch(
  `/owners/${t}`,
  e,
  n
), Rs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Bs(a, u, n);
  }, ...e };
}, lg = (t) => {
  const e = Rs(t);
  return c(e);
}, Fs = (t, e) => o.post(
  "/business",
  t,
  e
), Es = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fs(a, n);
  }, ...e };
}, mg = (t) => {
  const e = Es(t);
  return c(e);
}, Cs = (t, e) => o.post(
  "/merchant_bank/business",
  t,
  e
), vs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cs(a, n);
  }, ...e };
}, gg = (t) => {
  const e = vs(t);
  return c(e);
}, Us = (t) => o.post(
  "/amazon_redirect_url",
  void 0,
  t
), Ns = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Us(n), ...e };
}, qg = (t) => {
  const e = Ns(t);
  return c(e);
}, Ds = (t, e) => o.post(
  "/spd",
  t,
  e
), As = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ds(a, n);
  }, ...e };
}, fg = (t) => {
  const e = As(t);
  return c(e);
}, Ss = (t, e) => o.post(
  "/capital_product_business",
  t,
  e
), Gs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ss(a, n);
  }, ...e };
}, Og = (t) => {
  const e = Gs(t);
  return c(e);
}, Is = (t, e) => o.patch(
  "/flex_loan_age",
  t,
  e
), Ts = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Is(a, n);
  }, ...e };
}, hg = (t) => {
  const e = Ts(t);
  return c(e);
}, ws = (t, e) => o.patch(
  "/flex_loan_ownership",
  t,
  e
), Qs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ws(a, n);
  }, ...e };
}, Kg = (t) => {
  const e = Qs(t);
  return c(e);
}, Ls = (t, e) => o.patch(
  "/flex_loan_fund",
  t,
  e
), Ws = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ls(a, n);
  }, ...e };
}, bg = (t) => {
  const e = Ws(t);
  return c(e);
}, zs = (t, e) => o.post(
  "/setup_amazon_top_up",
  void 0,
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $s = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { params: a } = s ?? {};
    return zs(a, n);
  }, ...e };
}, kg = (t) => {
  const e = $s(t);
  return c(e);
}, Vs = (t, e) => o.get(
  "/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hs = (t) => ["/cards", ...t ? [t] : []], Ys = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hs(t), queryFn: ({ signal: u }) => Vs(t, { signal: u, ...r }), ...n };
};
function Mg(t, e) {
  const n = Ys(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const js = (t, e) => o.post(
  "/cards",
  t,
  e
), Xs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return js(a, n);
  }, ...e };
}, Pg = (t) => {
  const e = Xs(t);
  return c(e);
}, Js = (t, e) => o.get(
  "/businesses/products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zs = (t) => ["/businesses/products", ...t ? [t] : []], ta = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zs(t), queryFn: ({ signal: u }) => Js(t, { signal: u, ...r }), ...n };
};
function xg(t, e) {
  const n = ta(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ea = (t, e) => o.post(
  "/business/auth_link",
  t,
  e
), na = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ea(a, n);
  }, ...e };
}, Bg = (t) => {
  const e = na(t);
  return c(e);
}, ra = (t, e) => o.get(
  "/document_upload/group",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sa = (t) => ["/document_upload/group", ...t ? [t] : []], aa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sa(t), queryFn: ({ signal: u }) => ra(t, { signal: u, ...r }), ...n };
};
function Rg(t, e) {
  const n = aa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const oa = (t, e) => o.post(
  "/document_upload/group",
  t,
  e
), ua = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return oa(a, n);
  }, ...e };
}, Fg = (t) => {
  const e = ua(t);
  return c(e);
}, ia = (t, e) => o.get(
  "/document_upload/list",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ca = (t) => ["/document_upload/list", ...t ? [t] : []], ya = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ca(t), queryFn: ({ signal: u }) => ia(t, { signal: u, ...r }), ...n };
};
function Eg(t, e) {
  const n = ya(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const da = (t, e) => o.get(
  "/restriction",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _a = (t) => ["/restriction", ...t ? [t] : []], pa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _a(t), queryFn: ({ signal: u }) => da(t, { signal: u, ...r }), ...n };
};
function Cg(t, e) {
  const n = pa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const la = (t, e) => o.post(
  "/restriction",
  t,
  e
), ma = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return la(a, n);
  }, ...e };
}, vg = (t) => {
  const e = ma(t);
  return c(e);
}, ga = (t, e) => o.get(
  "/restrictions/details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qa = (t) => ["/restrictions/details", ...t ? [t] : []], fa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qa(t), queryFn: ({ signal: u }) => ga(t, { signal: u, ...r }), ...n };
};
function Ug(t, e) {
  const n = fa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Oa = (t, e) => o.get(
  "/liens",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ha = (t) => ["/liens", ...t ? [t] : []], Ka = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ha(t), queryFn: ({ signal: u }) => Oa(t, { signal: u, ...r }), ...n };
};
function Ng(t, e) {
  const n = Ka(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ba = (t, e, n) => o.patch(
  `/liens/${t}`,
  e,
  n
), ka = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return ba(a, u, n);
  }, ...e };
}, Dg = (t) => {
  const e = ka(t);
  return c(e);
}, Ma = (t, e) => o.post(
  "/opt_ins/trigger",
  t,
  e
), Pa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ma(a, n);
  }, ...e };
}, Ag = (t) => {
  const e = Pa(t);
  return c(e);
}, xa = (t, e) => o.get(
  "/businesses/daily_sales_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ba = (t) => ["/businesses/daily_sales_records", ...t ? [t] : []], Ra = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ba(t), queryFn: ({ signal: u }) => xa(t, { signal: u, ...r }), ...n };
};
function Sg(t, e) {
  const n = Ra(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fa = (t, e) => o.get(
  "/parafinder/businesses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ea = (t) => ["/parafinder/businesses", ...t ? [t] : []], Ca = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ea(t), queryFn: ({ signal: u }) => Fa(t, { signal: u, ...r }), ...n };
};
function Gg(t, e) {
  const n = Ca(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const va = (t) => o.get(
  "/parafinder/business_types",
  t
), Ua = () => ["/parafinder/business_types"], Na = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ua(), queryFn: ({ signal: a }) => va({ signal: a, ...n }), ...e };
};
function Ig(t) {
  const e = Na(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Da = (t, e) => o.get(
  "/parafinder/summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Aa = (t) => ["/parafinder/summary", ...t ? [t] : []], Sa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Aa(t), queryFn: ({ signal: u }) => Da(t, { signal: u, ...r }), ...n };
};
function Tg(t, e) {
  const n = Sa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ga = (t, e) => o.get(
  "/parafinderV2/capital_info_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ia = (t) => ["/parafinderV2/capital_info_summary", ...t ? [t] : []], Ta = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ia(t), queryFn: ({ signal: u }) => Ga(t, { signal: u, ...r }), ...n };
};
function wg(t, e) {
  const n = Ta(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const wa = (t, e) => o.get(
  `/merchant_bank/accounts/${t}`,
  e
), Qa = (t) => [`/merchant_bank/accounts/${t}`], La = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qa(t), queryFn: ({ signal: u }) => wa(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Qg(t, e) {
  const n = La(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Wa = (t, e) => o.get(
  `/merchant_bank/accounts/${t}/balances`,
  e
), za = (t) => [`/merchant_bank/accounts/${t}/balances`], $a = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? za(t), queryFn: ({ signal: u }) => Wa(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Lg(t, e) {
  const n = $a(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Va = (t, e) => o.get(
  "/merchant_bank/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ha = (t) => ["/merchant_bank/accounts", ...t ? [t] : []], Ya = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ha(t), queryFn: ({ signal: u }) => Va(t, { signal: u, ...r }), ...n };
};
function Wg(t, e) {
  const n = Ya(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ja = (t, e) => o.post(
  "/merchant_bank/accounts",
  t,
  e
), Xa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ja(a, n);
  }, ...e };
}, zg = (t) => {
  const e = Xa(t);
  return c(e);
}, Ja = (t, e) => o.post(
  "/merchant_bank/inner_transfers",
  t,
  e
), Za = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ja(a, n);
  }, ...e };
}, $g = (t) => {
  const e = Za(t);
  return c(e);
}, to = (t, e, n) => o.get(
  `/merchant_bank/transactions/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), eo = (t, e) => [`/merchant_bank/transactions/${t}`, ...e ? [e] : []], no = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? eo(t, e), queryFn: ({ signal: y }) => to(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Vg(t, e, n) {
  const r = no(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const ro = (t, e) => o.get(
  "/merchant_bank/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), so = (t) => ["/merchant_bank/transactions", ...t ? [t] : []], ao = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? so(t), queryFn: ({ signal: u }) => ro(t, { signal: u, ...r }), ...n };
};
function Hg(t, e) {
  const n = ao(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const oo = (t) => o.get(
  "/merchant_bank/transfer_fees",
  t
), uo = () => ["/merchant_bank/transfer_fees"], io = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? uo(), queryFn: ({ signal: a }) => oo({ signal: a, ...n }), ...e };
};
function Yg(t) {
  const e = io(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const co = (t, e) => o.get(
  "/merchant_bank/transfer_limits",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yo = (t) => ["/merchant_bank/transfer_limits", ...t ? [t] : []], _o = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yo(t), queryFn: ({ signal: u }) => co(t, { signal: u, ...r }), ...n };
};
function jg(t, e) {
  const n = _o(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const po = (t) => o.get(
  "/merchant_bank/interest_details",
  t
), lo = () => ["/merchant_bank/interest_details"], mo = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? lo(), queryFn: ({ signal: a }) => po({ signal: a, ...n }), ...e };
};
function Xg(t) {
  const e = mo(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const go = (t, e) => o.get(
  "/merchant_bank/recipients",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qo = (t) => ["/merchant_bank/recipients", ...t ? [t] : []], fo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qo(t), queryFn: ({ signal: u }) => go(t, { signal: u, ...r }), ...n };
};
function Jg(t, e) {
  const n = fo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Oo = (t, e) => o.post(
  "/merchant_bank/recipients",
  t,
  e
), ho = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Oo(a, n);
  }, ...e };
}, Zg = (t) => {
  const e = ho(t);
  return c(e);
}, Ko = (t, e) => o.get(
  `/merchant_bank/recipients/${t}`,
  e
), bo = (t) => [`/merchant_bank/recipients/${t}`], ko = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bo(t), queryFn: ({ signal: u }) => Ko(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function tq(t, e) {
  const n = ko(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Mo = (t, e) => o.delete(
  `/merchant_bank/recipients/${t}`,
  e
), Po = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Mo(a, n);
  }, ...e };
}, eq = (t) => {
  const e = Po(t);
  return c(e);
}, xo = (t, e, n) => o.patch(
  `/merchant_bank/recipients/${t}`,
  e,
  n
), Bo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return xo(a, u, n);
  }, ...e };
}, nq = (t) => {
  const e = Bo(t);
  return c(e);
}, Ro = (t, e, n) => o.post(
  `/merchant_bank/recipients/${t}/payment_rails`,
  e,
  n
), Fo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ro(a, u, n);
  }, ...e };
}, rq = (t) => {
  const e = Fo(t);
  return c(e);
}, Eo = (t, e) => o.post(
  "/merchant_bank/recipient_transfers",
  t,
  e
), Co = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Eo(a, n);
  }, ...e };
}, sq = (t) => {
  const e = Co(t);
  return c(e);
}, vo = (t, e, n) => o.patch(
  `/merchant_bank/recipient_transfers/${t}`,
  e,
  n
), Uo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return vo(a, u, n);
  }, ...e };
}, aq = (t) => {
  const e = Uo(t);
  return c(e);
}, No = (t, e) => o.get(
  "/merchant_bank/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Do = (t) => ["/merchant_bank/cards", ...t ? [t] : []], Ao = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Do(t), queryFn: ({ signal: u }) => No(t, { signal: u, ...r }), ...n };
};
function oq(t, e) {
  const n = Ao(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const So = (t, e) => o.post(
  "/merchant_bank/cards",
  t,
  e
), Go = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return So(a, n);
  }, ...e };
}, uq = (t) => {
  const e = Go(t);
  return c(e);
}, Io = (t, e, n) => o.patch(
  `/merchant_bank/card/cardholders/${t}`,
  e,
  n
), To = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Io(a, u, n);
  }, ...e };
}, iq = (t) => {
  const e = To(t);
  return c(e);
}, wo = (t, e) => o.get(
  "/merchant_bank/card/cardholders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qo = (t) => ["/merchant_bank/card/cardholders", ...t ? [t] : []], Lo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qo(t), queryFn: ({ signal: u }) => wo(t, { signal: u, ...r }), ...n };
};
function cq(t, e) {
  const n = Lo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Wo = (t, e) => o.post(
  "/merchant_bank/card/activate",
  t,
  e
), zo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Wo(a, n);
  }, ...e };
}, yq = (t) => {
  const e = zo(t);
  return c(e);
}, $o = (t, e) => o.get(
  "/merchant_bank/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vo = (t) => ["/merchant_bank/card/access_token", ...t ? [t] : []], Ho = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vo(t), queryFn: ({ signal: u }) => $o(t, { signal: u, ...r }), ...n };
};
function dq(t, e) {
  const n = Ho(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Yo = (t, e) => o.post(
  "/merchant_bank/card/apple_pay",
  t,
  e
), jo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yo(a, n);
  }, ...e };
}, _q = (t) => {
  const e = jo(t);
  return c(e);
}, Xo = (t, e) => o.get(
  "/merchant_bank/card/cardholder_access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jo = (t) => ["/merchant_bank/card/cardholder_access_token", ...t ? [t] : []], Zo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jo(t), queryFn: ({ signal: u }) => Xo(t, { signal: u, ...r }), ...n };
};
function pq(t, e) {
  const n = Zo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const tu = (t, e) => o.post(
  "/merchant_bank/card/state_transition",
  t,
  e
), eu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return tu(a, n);
  }, ...e };
}, lq = (t) => {
  const e = eu(t);
  return c(e);
}, nu = (t, e) => o.post(
  "/merchant_bank/card/google_pay",
  t,
  e
), ru = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return nu(a, n);
  }, ...e };
}, mq = (t) => {
  const e = ru(t);
  return c(e);
}, su = (t, e) => o.get(
  "/merchant_bank/card/disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), au = (t) => ["/merchant_bank/card/disputes", ...t ? [t] : []], ou = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? au(t), queryFn: ({ signal: u }) => su(t, { signal: u, ...r }), ...n };
};
function gq(t, e) {
  const n = ou(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const uu = (t, e) => o.post(
  "/merchant_bank/card/disputes",
  t,
  e
), iu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return uu(a, n);
  }, ...e };
}, qq = (t) => {
  const e = iu(t);
  return c(e);
}, cu = (t, e) => o.post(
  "/merchant_bank/card/dispute/state_transition",
  t,
  e
), yu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return cu(a, n);
  }, ...e };
}, fq = (t) => {
  const e = yu(t);
  return c(e);
}, du = (t, e) => o.post(
  "/merchant_bank/card/dispute/chargeback_credit",
  t,
  e
), _u = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return du(a, n);
  }, ...e };
}, Oq = (t) => {
  const e = _u(t);
  return c(e);
}, pu = (t, e) => o.get(
  "/merchant_bank/external_accounts/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), lu = (t) => ["/merchant_bank/external_accounts/plaid_link_token", ...t ? [t] : []], mu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lu(t), queryFn: ({ signal: u }) => pu(t, { signal: u, ...r }), ...n };
};
function hq(t, e) {
  const n = mu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gu = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}/plaid_link_token`,
  e
), qu = (t) => [`/merchant_bank/external_accounts/${t}/plaid_link_token`], fu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qu(t), queryFn: ({ signal: u }) => gu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Kq(t, e) {
  const n = fu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ou = (t, e) => o.get(
  "/merchant_bank/external_accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hu = (t) => ["/merchant_bank/external_accounts", ...t ? [t] : []], Ku = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hu(t), queryFn: ({ signal: u }) => Ou(t, { signal: u, ...r }), ...n };
};
function bq(t, e) {
  const n = Ku(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const bu = (t, e) => o.post(
  "/merchant_bank/external_accounts",
  t,
  e
), ku = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return bu(a, n);
  }, ...e };
}, kq = (t) => {
  const e = ku(t);
  return c(e);
}, Mu = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}`,
  e
), Pu = (t) => [`/merchant_bank/external_accounts/${t}`], xu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pu(t), queryFn: ({ signal: u }) => Mu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Mq(t, e) {
  const n = xu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Bu = (t, e) => o.delete(
  `/merchant_bank/external_accounts/${t}`,
  e
), Ru = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Bu(a, n);
  }, ...e };
}, Pq = (t) => {
  const e = Ru(t);
  return c(e);
}, Fu = (t, e, n) => o.patch(
  `/merchant_bank/external_accounts/${t}`,
  e,
  n
), Eu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Fu(a, u, n);
  }, ...e };
}, xq = (t) => {
  const e = Eu(t);
  return c(e);
}, Cu = (t, e) => o.post(
  "/merchant_bank/external_bank_transfers",
  t,
  e
), vu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cu(a, n);
  }, ...e };
}, Bq = (t) => {
  const e = vu(t);
  return c(e);
}, Uu = (t, e, n) => o.patch(
  `/merchant_bank/external_bank_transfers/${t}`,
  e,
  n
), Nu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Uu(a, u, n);
  }, ...e };
}, Rq = (t) => {
  const e = Nu(t);
  return c(e);
}, Du = (t, e) => o.get(
  "/debts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Au = (t) => ["/debts", ...t ? [t] : []], Su = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Au(t), queryFn: ({ signal: u }) => Du(t, { signal: u, ...r }), ...n };
};
function Fq(t, e) {
  const n = Su(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Gu = (t, e) => o.post(
  "/debts",
  t,
  e
), Iu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gu(a, n);
  }, ...e };
}, Eq = (t) => {
  const e = Iu(t);
  return c(e);
}, Tu = (t, e) => o.post(
  "/debts/check_complete",
  t,
  e
), wu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Tu(a, n);
  }, ...e };
}, Cq = (t) => {
  const e = wu(t);
  return c(e);
}, Qu = (t, e) => o.get(
  "/debt_checks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lu = (t) => ["/debt_checks", ...t ? [t] : []], Wu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lu(t), queryFn: ({ signal: u }) => Qu(t, { signal: u, ...r }), ...n };
};
function vq(t, e) {
  const n = Wu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const zu = (t, e) => o.post(
  "/card/close_account",
  t,
  e
), $u = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return zu(a, n);
  }, ...e };
}, Uq = (t) => {
  const e = $u(t);
  return c(e);
}, Vu = (t, e) => o.get(
  "/financial_reviews",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hu = (t) => ["/financial_reviews", ...t ? [t] : []], Yu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hu(t), queryFn: ({ signal: u }) => Vu(t, { signal: u, ...r }), ...n };
};
function Nq(t, e) {
  const n = Yu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ju = (t, e) => o.post(
  "/financial_reviews",
  t,
  e
), Xu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ju(a, n);
  }, ...e };
}, Dq = (t) => {
  const e = Xu(t);
  return c(e);
}, Ju = (t, e) => o.post(
  "/tax_records",
  t,
  e
), Zu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ju(a, n);
  }, ...e };
}, Aq = (t) => {
  const e = Zu(t);
  return c(e);
}, ti = (t, e) => o.get(
  "/officer_subsidiary_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ei = (t) => ["/officer_subsidiary_records", ...t ? [t] : []], ni = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ei(t), queryFn: ({ signal: u }) => ti(t, { signal: u, ...r }), ...n };
};
function Sq(t, e) {
  const n = ni(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ri = (t, e) => o.post(
  "/officer_subsidiary_records",
  t,
  e
), si = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ri(a, n);
  }, ...e };
}, Gq = (t) => {
  const e = si(t);
  return c(e);
}, ai = (t) => o.get(
  "/analytics/cube_token",
  t
), oi = () => ["/analytics/cube_token"], ui = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? oi(), queryFn: ({ signal: a }) => ai({ signal: a, ...n }), ...e };
};
function Iq(t) {
  const e = ui(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ii = (t, e) => o.get(
  "/logs/api_request",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ci = (t) => ["/logs/api_request", ...t ? [t] : []], yi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ci(t), queryFn: ({ signal: u }) => ii(t, { signal: u, ...r }), ...n };
};
function Tq(t, e) {
  const n = yi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const di = (t) => o.get(
  "/marketing/offer_csvs",
  t
), _i = () => ["/marketing/offer_csvs"], pi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? _i(), queryFn: ({ signal: a }) => di({ signal: a, ...n }), ...e };
};
function wq(t) {
  const e = pi(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const li = (t) => o.post(
  "/marketing/offer_csvs/generate",
  void 0,
  t
), mi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => li(n), ...e };
}, Qq = (t) => {
  const e = mi(t);
  return c(e);
}, gi = (t, e) => o.get(
  "/businesses/notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qi = (t) => ["/businesses/notifications", ...t ? [t] : []], fi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qi(t), queryFn: ({ signal: u }) => gi(t, { signal: u, ...r }), ...n };
};
function Lq(t, e) {
  const n = fi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Oi = (t, e) => o.post(
  "/businesses/notifications",
  t,
  e
), hi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Oi(a, n);
  }, ...e };
}, Wq = (t) => {
  const e = hi(t);
  return c(e);
}, Ki = (t, e) => o.post(
  "/terms_of_service_mark_shown",
  t,
  e
), bi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ki(a, n);
  }, ...e };
}, zq = (t) => {
  const e = bi(t);
  return c(e);
}, ki = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/applications`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Mi = (t, e) => [`/partner_dashboard/business_details_page/${t}/applications`, ...e ? [e] : []], Pi = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Mi(t, e), queryFn: ({ signal: y }) => ki(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function $q(t, e, n) {
  const r = Pi(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const xi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/bank_info`,
  e
), Bi = (t) => [`/partner_dashboard/business_details_page/${t}/bank_info`], Ri = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bi(t), queryFn: ({ signal: u }) => xi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Vq(t, e) {
  const n = Ri(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/business_info`,
  e
), Ei = (t) => [`/partner_dashboard/business_details_page/${t}/business_info`], Ci = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ei(t), queryFn: ({ signal: u }) => Fi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Hq(t, e) {
  const n = Ci(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const vi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_products`,
  e
), Ui = (t) => [`/partner_dashboard/business_details_page/${t}/funded_products`], Ni = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ui(t), queryFn: ({ signal: u }) => vi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Yq(t, e) {
  const n = Ni(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Di = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_product_details`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ai = (t, e) => [`/partner_dashboard/business_details_page/${t}/funded_product_details`, ...e ? [e] : []], Si = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ai(t, e), queryFn: ({ signal: y }) => Di(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function jq(t, e, n) {
  const r = Si(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Gi = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/payment_progress`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ii = (t, e) => [`/partner_dashboard/business_details_page/${t}/payment_progress`, ...e ? [e] : []], Ti = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ii(t, e), queryFn: ({ signal: y }) => Gi(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Xq(t, e, n) {
  const r = Ti(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const wi = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/offers`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Qi = (t, e) => [`/partner_dashboard/business_details_page/${t}/offers`, ...e ? [e] : []], Li = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Qi(t, e), queryFn: ({ signal: y }) => wi(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Jq(t, e, n) {
  const r = Li(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Wi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/owner_info`,
  e
), zi = (t) => [`/partner_dashboard/business_details_page/${t}/owner_info`], $i = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zi(t), queryFn: ({ signal: u }) => Wi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Zq(t, e) {
  const n = $i(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vi = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hi = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows/count", ...t ? [t] : []], Yi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hi(t), queryFn: ({ signal: u }) => Vi(t, { signal: u, ...r }), ...n };
};
function tf(t, e) {
  const n = Yi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ji = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xi = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows", ...t ? [t] : []], Ji = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xi(t), queryFn: ({ signal: u }) => ji(t, { signal: u, ...r }), ...n };
};
function ef(t, e) {
  const n = Ji(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zi = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows/count", ...t ? [t] : []], ec = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tc(t), queryFn: ({ signal: u }) => Zi(t, { signal: u, ...r }), ...n };
};
function nf(t, e) {
  const n = ec(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const nc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows", ...t ? [t] : []], sc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rc(t), queryFn: ({ signal: u }) => nc(t, { signal: u, ...r }), ...n };
};
function rf(t, e) {
  const n = sc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ac = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), oc = (t) => ["/partner_dashboard/capital_tab/applications_table_rows/count", ...t ? [t] : []], uc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oc(t), queryFn: ({ signal: u }) => ac(t, { signal: u, ...r }), ...n };
};
function sf(t, e) {
  const n = uc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ic = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), cc = (t) => ["/partner_dashboard/capital_tab/applications_table_rows", ...t ? [t] : []], yc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cc(t), queryFn: ({ signal: u }) => ic(t, { signal: u, ...r }), ...n };
};
function af(t, e) {
  const n = yc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const dc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _c = (t) => ["/partner_dashboard/capital_tab/offers_table_rows/count", ...t ? [t] : []], pc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _c(t), queryFn: ({ signal: u }) => dc(t, { signal: u, ...r }), ...n };
};
function of(t, e) {
  const n = pc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const lc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mc = (t) => ["/partner_dashboard/capital_tab/offers_table_rows", ...t ? [t] : []], gc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mc(t), queryFn: ({ signal: u }) => lc(t, { signal: u, ...r }), ...n };
};
function uf(t, e) {
  const n = gc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qc = (t) => o.get(
  "/partner_dashboard/platform_tab/partner_support_info",
  t
), fc = () => ["/partner_dashboard/platform_tab/partner_support_info"], Oc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? fc(), queryFn: ({ signal: a }) => qc({ signal: a, ...n }), ...e };
};
function cf(t) {
  const e = Oc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const hc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/offer_url`,
  e
), Kc = (t) => [`/partner_dashboard/business_details_page/${t}/offer_url`], bc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kc(t), queryFn: ({ signal: u }) => hc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function yf(t, e) {
  const n = bc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const kc = (t) => o.get(
  "/partner_dashboard/crm/integration",
  t
), Mc = () => ["/partner_dashboard/crm/integration"], Pc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Mc(), queryFn: ({ signal: a }) => kc({ signal: a, ...n }), ...e };
};
function df(t) {
  const e = Pc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const xc = (t) => o.get(
  "/partner_dashboard/valid_object_id_prefixes",
  t
), Bc = () => ["/partner_dashboard/valid_object_id_prefixes"], Rc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Bc(), queryFn: ({ signal: a }) => xc({ signal: a, ...n }), ...e };
};
function _f(t) {
  const e = Rc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Fc = (t, e) => o.get(
  "/partner_dashboard/webhook_logs/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ec = (t) => ["/partner_dashboard/webhook_logs/events", ...t ? [t] : []], Cc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ec(t), queryFn: ({ signal: u }) => Fc(t, { signal: u, ...r }), ...n };
};
function pf(t, e) {
  const n = Cc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const vc = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}`,
  e
), Uc = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}`], Nc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uc(t), queryFn: ({ signal: u }) => vc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function lf(t, e) {
  const n = Nc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Dc = (t, e, n) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ac = (t, e) => [`/partner_dashboard/webhook_logs/event_info/${t}/attempts`, ...e ? [e] : []], Sc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ac(t, e), queryFn: ({ signal: y }) => Dc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function mf(t, e, n) {
  const r = Sc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Gc = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`,
  e
), Ic = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`], Tc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ic(t), queryFn: ({ signal: u }) => Gc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function gf(t, e) {
  const n = Tc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const wc = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend",
  t,
  e
), Qc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return wc(a, n);
  }, ...e };
}, qf = (t) => {
  const e = Qc(t);
  return c(e);
}, Lc = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend_event",
  t,
  e
), Wc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Lc(a, n);
  }, ...e };
}, ff = (t) => {
  const e = Wc(t);
  return c(e);
}, zc = (t, e) => o.get(
  "/partner_dashboard/crm/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $c = (t) => ["/partner_dashboard/crm/events", ...t ? [t] : []], Vc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $c(t), queryFn: ({ signal: u }) => zc(t, { signal: u, ...r }), ...n };
};
function Of(t, e) {
  const n = Vc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Hc = (t, e, n) => o.get(
  `/partner_dashboard/crm/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Yc = (t, e) => [`/partner_dashboard/crm/event_info/${t}/attempts`, ...e ? [e] : []], jc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Yc(t, e), queryFn: ({ signal: y }) => Hc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function hf(t, e, n) {
  const r = jc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Xc = (t, e) => o.post(
  "/partner_dashboard/crm/resend",
  t,
  e
), Jc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Xc(a, n);
  }, ...e };
}, Kf = (t) => {
  const e = Jc(t);
  return c(e);
}, Zc = (t, e) => o.get(
  `/partner_dashboard/crm/event_info/${t}`,
  e
), ty = (t) => [`/partner_dashboard/crm/event_info/${t}`], ey = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ty(t), queryFn: ({ signal: u }) => Zc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function bf(t, e) {
  const n = ey(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ny = (t) => o.post(
  "/partner_dashboard/crm/trigger_sync",
  void 0,
  t
), ry = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ny(n), ...e };
}, kf = (t) => {
  const e = ry(t);
  return c(e);
}, sy = (t) => o.get(
  "/partner_dashboard/crm/sync",
  t
), ay = () => ["/partner_dashboard/crm/sync"], oy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ay(), queryFn: ({ signal: a }) => sy({ signal: a, ...n }), ...e };
};
function Mf(t) {
  const e = oy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const uy = (t) => o.post(
  "/partner_dashboard/crm/sync",
  void 0,
  t
), iy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => uy(n), ...e };
}, Pf = (t) => {
  const e = iy(t);
  return c(e);
}, cy = (t, e) => o.get(
  "/partner_dashboard/webhook_endpoints",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yy = (t) => ["/partner_dashboard/webhook_endpoints", ...t ? [t] : []], dy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yy(t), queryFn: ({ signal: u }) => cy(t, { signal: u, ...r }), ...n };
};
function xf(t, e) {
  const n = dy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const _y = (t, e) => o.post(
  "/partner_dashboard/webhook_endpoints",
  t,
  e
), py = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return _y(a, n);
  }, ...e };
}, Bf = (t) => {
  const e = py(t);
  return c(e);
}, ly = (t, e) => o.delete(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e
), my = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return ly(a, n);
  }, ...e };
}, Rf = (t) => {
  const e = my(t);
  return c(e);
}, gy = (t, e, n) => o.patch(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e,
  n
), qy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return gy(a, u, n);
  }, ...e };
}, Ff = (t) => {
  const e = qy(t);
  return c(e);
}, fy = (t, e) => o.get(
  "/partner_dashboard/capital_tab/csv_export",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Oy = (t) => ["/partner_dashboard/capital_tab/csv_export", ...t ? [t] : []], hy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Oy(t), queryFn: ({ signal: u }) => fy(t, { signal: u, ...r }), ...n };
};
function Ef(t, e) {
  const n = hy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ky = (t, e) => o.post(
  "/partner_dashboard/capital_tab/csv_export",
  t,
  e
), by = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ky(a, n);
  }, ...e };
}, Cf = (t) => {
  const e = by(t);
  return c(e);
}, ky = (t, e) => o.get(
  "/rutter_connections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), My = (t) => ["/rutter_connections", ...t ? [t] : []], Py = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? My(t), queryFn: ({ signal: u }) => ky(t, { signal: u, ...r }), ...n };
};
function vf(t, e) {
  const n = Py(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xy = (t, e) => o.post(
  "/rutter_connections",
  t,
  e
), By = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xy(a, n);
  }, ...e };
}, Uf = (t) => {
  const e = By(t);
  return c(e);
}, Ry = (t, e) => o.post(
  "/mark_accounting_integration_seen",
  t,
  e
), Fy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ry(a, n);
  }, ...e };
}, Nf = (t) => {
  const e = Fy(t);
  return c(e);
}, Ey = (t) => o.get(
  "/sardine/session_id",
  t
), Cy = () => ["/sardine/session_id"], vy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Cy(), queryFn: ({ signal: a }) => Ey({ signal: a, ...n }), ...e };
};
function Df(t) {
  const e = vy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Uy = (t, e) => o.post(
  "/sardine/session_id",
  t,
  e
), Ny = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Uy(a, n);
  }, ...e };
}, Af = (t) => {
  const e = Ny(t);
  return c(e);
}, Dy = (t) => o.get(
  "/partner/widget_configs",
  t
), Ay = () => ["/partner/widget_configs"], Sy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ay(), queryFn: ({ signal: a }) => Dy({ signal: a, ...n }), ...e };
};
function Sf(t) {
  const e = Sy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Gy = (t, e) => o.patch(
  "/partner/widget_configs",
  t,
  e
), Iy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gy(a, n);
  }, ...e };
}, Gf = (t) => {
  const e = Iy(t);
  return c(e);
}, Ty = (t, e) => o.get(
  `/merchant_bank/applications/${t}`,
  e
), wy = (t) => [`/merchant_bank/applications/${t}`], Qy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wy(t), queryFn: ({ signal: u }) => Ty(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function If(t, e) {
  const n = Qy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ly = (t, e) => o.get(
  "/merchant_bank/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wy = (t) => ["/merchant_bank/applications", ...t ? [t] : []], zy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wy(t), queryFn: ({ signal: u }) => Ly(t, { signal: u, ...r }), ...n };
};
function Tf(t, e) {
  const n = zy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $y = (t, e) => o.post(
  "/merchant_bank/applications",
  t,
  e
), Vy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $y(a, n);
  }, ...e };
}, wf = (t) => {
  const e = Vy(t);
  return c(e);
}, Hy = (t, e) => o.post(
  "/webhooks/plaid/general",
  t,
  e
), Yy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hy(a, n);
  }, ...e };
}, Qf = (t) => {
  const e = Yy(t);
  return c(e);
}, jy = (t, e) => o.get(
  "/amazon/funding/account_lock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xy = (t) => ["/amazon/funding/account_lock", ...t ? [t] : []], Jy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xy(t), queryFn: ({ signal: u }) => jy(t, { signal: u, ...r }), ...n };
};
function Lf(t, e) {
  const n = Jy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zy = (t) => o.get(
  "/naics",
  t
), td = () => ["/naics"], ed = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? td(), queryFn: ({ signal: a }) => Zy({ signal: a, ...n }), ...e };
};
function Wf(t) {
  const e = ed(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const nd = (t, e, n, r) => o.post(
  `/mfa/otp/send/${t}/${e}`,
  n,
  r
), rd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return nd(a, u, y, n);
  }, ...e };
}, zf = (t) => {
  const e = rd(t);
  return c(e);
}, sd = (t, e, n, r) => o.post(
  `/mfa/otp/verify/${t}/${e}`,
  n,
  r
), ad = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return sd(a, u, y, n);
  }, ...e };
}, $f = (t) => {
  const e = ad(t);
  return c(e);
}, od = (t, e) => o.get(
  "/funding/pad_agreement",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ud = (t) => ["/funding/pad_agreement", ...t ? [t] : []], id = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ud(t), queryFn: ({ signal: u }) => od(t, { signal: u, ...r }), ...n };
};
function Vf(t, e) {
  const n = id(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const cd = (t, e) => o.post(
  "/funding/mark_pad_agreement_signed",
  t,
  e
), yd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return cd(a, n);
  }, ...e };
}, Hf = (t) => {
  const e = yd(t);
  return c(e);
}, dd = (t, e) => o.get(
  "/templates",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _d = (t) => ["/templates", ...t ? [t] : []], pd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _d(t), queryFn: ({ signal: u }) => dd(t, { signal: u, ...r }), ...n };
};
function Yf(t, e) {
  const n = pd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ld = (t, e) => o.post(
  "/templates",
  t,
  e
), md = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ld(a, n);
  }, ...e };
}, jf = (t) => {
  const e = md(t);
  return c(e);
}, gd = (t, e) => o.patch(
  "/templates",
  t,
  e
), qd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return gd(a, n);
  }, ...e };
}, Xf = (t) => {
  const e = qd(t);
  return c(e);
}, fd = (t, e) => o.get(
  "/template_mock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Od = (t) => ["/template_mock", ...t ? [t] : []], hd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Od(t), queryFn: ({ signal: u }) => fd(t, { signal: u, ...r }), ...n };
};
function Jf(t, e) {
  const n = hd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Kd = (t, e) => o.get(
  "/template_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), bd = (t) => ["/template_snapshots", ...t ? [t] : []], kd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bd(t), queryFn: ({ signal: u }) => Kd(t, { signal: u, ...r }), ...n };
};
function Zf(t, e) {
  const n = kd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Md = (t, e) => o.get(
  "/template_snapshot_urls",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pd = (t) => ["/template_snapshot_urls", ...t ? [t] : []], xd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pd(t), queryFn: ({ signal: u }) => Md(t, { signal: u, ...r }), ...n };
};
function tO(t, e) {
  const n = xd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Bd = (t) => o.get(
  "/template_types",
  t
), Rd = () => ["/template_types"], Fd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Rd(), queryFn: ({ signal: a }) => Bd({ signal: a, ...n }), ...e };
};
function eO(t) {
  const e = Fd(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Ed = (t, e) => o.post(
  "/template_types",
  t,
  e
), Cd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ed(a, n);
  }, ...e };
}, nO = (t) => {
  const e = Cd(t);
  return c(e);
}, vd = (t, e) => o.post(
  "/manual_repayment",
  t,
  e
), Ud = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return vd(a, n);
  }, ...e };
}, rO = (t) => {
  const e = Ud(t);
  return c(e);
}, Nd = (t, e) => o.post(
  "/forgive_capital_fee",
  t,
  e
), Dd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Nd(a, n);
  }, ...e };
}, sO = (t) => {
  const e = Dd(t);
  return c(e);
}, Ad = (t, e) => o.post(
  "/mfa/verify_account_details",
  t,
  e
), Sd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ad(a, n);
  }, ...e };
}, aO = (t) => {
  const e = Sd(t);
  return c(e);
}, Gd = (t) => o.patch(
  "/mfa/extend_session",
  void 0,
  t
), Id = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Gd(n), ...e };
}, oO = (t) => {
  const e = Id(t);
  return c(e);
}, Td = (t, e) => o.get(
  "/merchant_bank/offers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wd = (t) => ["/merchant_bank/offers", ...t ? [t] : []], Qd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wd(t), queryFn: ({ signal: u }) => Td(t, { signal: u, ...r }), ...n };
};
function uO(t, e) {
  const n = Qd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ld = (t, e) => o.get(
  "/internal_api/sardine/score_details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wd = (t) => ["/internal_api/sardine/score_details", ...t ? [t] : []], zd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wd(t), queryFn: ({ signal: u }) => Ld(t, { signal: u, ...r }), ...n };
};
function iO(t, e) {
  const n = zd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $d = (t, e) => o.patch(
  "/merchant_bank/merchant_config",
  t,
  e
), Vd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $d(a, n);
  }, ...e };
}, cO = (t) => {
  const e = Vd(t);
  return c(e);
}, yO = (t, e) => i({
  queryKey: ["plaid-link-token", t],
  queryFn: () => o.get("/funding/plaid_link_token", { ...e == null ? void 0 : e.axios, params: t })
}), dO = (t, e) => i({
  queryKey: ["card-offers", t.business_id],
  queryFn: () => o.get("/card/offers", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), _O = (t, e) => {
  var n;
  return i({
    queryKey: ["card-application", t.business_id],
    queryFn: () => o.get("/card/applications", {
      ...e == null ? void 0 : e.axios,
      params: t
    }),
    enabled: (n = e == null ? void 0 : e.query) == null ? void 0 : n.enabled
  });
}, pO = (t, e) => i({
  queryKey: ["cardholder", t.business_id],
  queryFn: () => o.get("/cardholders", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), lO = (t, e) => o.get("/statements", {
  ...e == null ? void 0 : e.axios,
  params: t
}), mO = (t, e) => i({
  queryKey: ["card-transactions"],
  queryFn: async () => {
    const n = await o.get("/card/transactions", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.map((r) => ({
          ...r,
          amount: r.amount * -1,
          state: r.amount <= 0 || r.transaction_type === "chargeback" ? "succeeded" : "refunded",
          activity_type: r.transaction_type === "chargeback" ? "dispute_credit" : "transaction",
          ...r.transaction_type === "chargeback" && {
            related_card_transaction: n.data.results.find(
              ({ id: s }) => s === r.related_card_transaction_id
            )
          }
        }))
      }
    };
  }
}), gO = (t, e) => i({
  queryKey: ["pending-card-transactions"],
  queryFn: async () => {
    const n = await o.get("/card/pending_transactions", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.map((r) => ({
          ...r,
          amount: r.amount * -1,
          state: "pending",
          activity_type: "transaction"
        }))
      }
    };
  }
}), qO = (t, e) => i({
  queryKey: ["card-repayments"],
  queryFn: async () => {
    const n = await o.get("/repayments", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.filter((r) => r.state === "complete" || r.state === "pending").map((r) => ({
          ...r,
          timestamp: r.date,
          activity_type: "repayment",
          state: r.is_settled ? r.state : "pending"
        }))
      }
    };
  }
}), fO = (t, e) => i({
  queryKey: ["card-balance", t.business_id],
  queryFn: () => o.get("/card/balances", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), OO = (t, e) => i({
  queryKey: [`card-access-token-${t.card_id}`],
  queryFn: () => o.get("/card/access_token", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), hO = (t, e) => i({
  queryKey: ["microdeposit-link-token", t.business_id],
  queryFn: () => o.get("/funding/micro_deposit_verification_plaid_link_token", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), KO = (t, e) => i({
  queryKey: ["show-top-up-offer", t.business_id],
  queryFn: () => o.get("/businesses/show_top_up_offer", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), bO = (t, e) => i({
  queryKey: ["whitelisted-for-early-payment", t.cash_advance_id],
  queryFn: () => o.get("/early_manual_repayment_whitelist", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), kO = (t, e) => i({
  queryKey: ["opt-out-eligibility", t.business_external_id],
  queryFn: () => o.get("/opt_out_eligibility", { ...e == null ? void 0 : e.axios, params: t })
}), MO = (t, e) => {
  var r;
  const n = (r = e == null ? void 0 : e.query) == null ? void 0 : r.enabled;
  return i({
    queryKey: ["card-lifecycle-state", t.business_id],
    queryFn: () => o.get("/card/lifecycle_states", {
      ...e == null ? void 0 : e.axios,
      params: t
    }),
    enabled: n
  });
}, PO = (t, e) => i({
  queryKey: ["card-offer", t.business_id],
  queryFn: () => o.get("/card/offers", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), xO = (t, e) => i({
  queryKey: ["opt-ins", t.business_id],
  queryFn: () => o.get("/opt_ins", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), BO = (t) => c({
  mutationFn: (e) => o.post("/beneficial_owners", e, t == null ? void 0 : t.axios)
}), RO = (t) => c({
  mutationFn: ({
    id: e,
    ...n
  }) => o.patch(`/beneficial_owners/${e}`, n, t == null ? void 0 : t.axios)
}), FO = (t) => c({
  mutationFn: (e) => o.delete(`/beneficial_owners/${e}`, t == null ? void 0 : t.axios)
}), EO = (t) => c({
  mutationFn: (e) => o.post(`/business/${e.business_id}/kyc`, e, t == null ? void 0 : t.axios)
}), CO = (t) => c({
  mutationFn: (e) => o.post("/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), vO = (t) => c({
  mutationFn: (e) => o.post("/amazon/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), UO = (t) => c({
  mutationFn: (e) => o.post("/funding/bank_statement_upload", e, t == null ? void 0 : t.axios)
}), NO = (t) => c({
  mutationFn: (e) => o.post("/underwriting_attempts", { context: e }, t == null ? void 0 : t.axios)
}), DO = (t) => c({
  mutationFn: (e) => o.post(
    "/amazonlending/create_or_update_amazon_resources_pre_login",
    e,
    t == null ? void 0 : t.axios
  )
}), AO = (t) => c({
  mutationFn: (e) => o.patch("/boost_complete", e, t == null ? void 0 : t.axios)
}), SO = (t) => c({
  mutationFn: (e) => o.patch(`/cardholders/${e.id}`, e, t == null ? void 0 : t.axios)
}), GO = (t) => c({
  mutationFn: (e) => o.post("/card/disputes", e, t == null ? void 0 : t.axios)
}), IO = (t) => c({
  mutationFn: (e) => o.post("/card/activate", e, t == null ? void 0 : t.axios)
}), TO = (t) => c({
  mutationFn: async (e) => (await o.post("/card/apple_pay", e, t == null ? void 0 : t.axios)).data
}), wO = (t) => c({
  mutationFn: (e) => o.post("/card/google_pay", e, t == null ? void 0 : t.axios)
}), QO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/accounts/initiate_micro_deposit_verification",
    e,
    t == null ? void 0 : t.axios
  )
}), LO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/accounts/fail_micro_deposit_verification",
    e,
    t == null ? void 0 : t.axios
  )
}), WO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/create_unverified_bank_accounts_from_plaid_linkage",
    e,
    t == null ? void 0 : t.axios
  )
}), zO = (t) => c({
  mutationFn: (e) => o.post("/amazon/funding/verify_bank_account", e, t == null ? void 0 : t.axios)
}), $O = (t) => c({
  mutationFn: (e) => o.post("/funding/plaid_linkages", e, t == null ? void 0 : t.axios)
}), VO = (t) => c({
  mutationFn: (e) => o.post("/tickets", e, t == null ? void 0 : t.axios)
}), HO = (t) => c({
  mutationFn: (e) => o.post("/opt_out", e, t == null ? void 0 : t.axios)
}), YO = (t) => c({
  mutationFn: (e) => o.post("/opt_ins", e, t == null ? void 0 : t.axios)
}), jO = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  }
];
export {
  il as AccountOwner,
  ul as AddressType,
  ol as AdverseActionReason,
  al as BankAccountInfoDataShare,
  sl as BusinessProductType,
  rl as CapitalProductActivityType,
  nl as CapitalProductApplicationStatus,
  el as CapitalProductApplicationType,
  tl as CapitalProductHealthStatus,
  Zp as CapitalProductInternalRepaymentPlanType,
  Jp as CapitalProductOfferLabel,
  Xp as CapitalProductState,
  jp as CapitalProductType,
  Yp as CardBusinessTableRowStatus,
  Hp as CardDeserializerCardType,
  Wp as CardSerializer1FulfillmentStatus,
  Lp as CardSerializer1State,
  Vp as CardSerializerCardType,
  $p as CardSerializerFulfillmentStatus,
  zp as CardSerializerState,
  Qp as CardShippingMethod,
  wp as CardTransitionChannel,
  Tp as CardTransitionReasonCode,
  Ip as CardTransitionState,
  Gp as CardTransitionType,
  Sp as CashAdvanceChargeOffSerializerChargeOffType,
  Ap as ChargeOffInputSerializerChargeOffType,
  Dp as CountryCode,
  Np as CrmDeliveryAttemptState,
  Up as CrmEventType,
  vp as CrmVendor,
  Cp as Currency,
  Ep as DataRequirement,
  Fp as DatasetType,
  Rp as DatasetUploadStatus,
  Bp as DatashareMethod,
  xp as DeniedReason,
  Pp as Direction,
  Mp as DocumentUploadPurpose,
  kp as DoordashAdminUserMxRole,
  bp as EntityType,
  Kp as FieldType,
  hp as FinancialRecordDocType,
  Op as FutureActivityType,
  fp as IdentityDataShare,
  qp as IncorporationJurisdiction,
  gp as IncorporationType,
  mp as InfoNeededStatus,
  lp as IngestionStatus,
  pp as InnerTransferType,
  _p as KycProvider,
  dp as KycReviewReason,
  yp as LienStatus,
  cp as LienType,
  ip as LoanBusinessTableRowStatus,
  up as ManualReviewReason,
  op as McaBusinessTableRowStatus,
  ap as MerchantBankAccountType,
  sp as MerchantBankApplicationState,
  rp as MerchantBankCardDisputeReason,
  np as MerchantBankCardDisputeState,
  ep as MerchantBankCardPaymentPreference,
  tp as MerchantBankCardReplacementReason,
  Z_ as MerchantBankCardType,
  J_ as MerchantBankExternalAccountState,
  X_ as MerchantBankExternalBankAccountType,
  j_ as MerchantBankReceivedTransferType,
  Y_ as MerchantBankRecipientPaymentType,
  H_ as MerchantBankRepaymentType,
  V_ as MerchantBankTransactionDestinationAccountType,
  $_ as MerchantBankTransactionPaymentDirection,
  z_ as MerchantBankTransactionState,
  W_ as MerchantBankTransactionType,
  L_ as MerchantBankTransferPaymentType,
  Q_ as MicroDepositVerificationState,
  w_ as MinimumRepaymentAmountDueReason,
  T_ as NotificationType,
  I_ as OnboardingAction,
  G_ as OnboardingProductType,
  S_ as OnboardingState,
  A_ as OneOffActionType,
  D_ as OptInState,
  N_ as ParafinProduct,
  U_ as PersonaInquiryType,
  v_ as PhoneNumberCountryCode,
  C_ as PhoneNumberType,
  E_ as PlaidInvestigationDeniedReason,
  F_ as PlaidLinkageWithInvestigationSerializerState,
  R_ as RepaymentDetailsRepaymentPlanType,
  B_ as RepaymentDisbursementType,
  x_ as RepaymentPauseSerializerPauseType,
  P_ as RepaymentSchedule,
  M_ as ReplacementDetailsReplacementReason,
  k_ as RestrictionExternalReason,
  b_ as RestrictionReason,
  K_ as RestrictionSource,
  h_ as RestrictionType,
  O_ as ReturnCode,
  f_ as ReversalReason,
  q_ as RutterConnectionState,
  g_ as RutterIntegrationType,
  m_ as RutterPlatform,
  l_ as S3PartnerDashboardCsvStatus,
  p_ as S3PartnerDashboardCsvTableType,
  __ as SalesDataShare,
  d_ as SardineCustomerScoreState,
  y_ as SardineFlowName,
  c_ as SardineLevel,
  i_ as SessionType,
  u_ as State,
  o_ as StripeSaleRecordIngestionType,
  a_ as TermsOfServiceLinkType,
  s_ as UnderwritingDesiredOutputState,
  r_ as UserRole,
  n_ as ValidationStatus,
  e_ as VerificationStatus,
  t_ as WebhookDeliveryAttemptState,
  Zd as WebhookEndpointStatus,
  Jd as WebhookEventDeliveryState,
  Xd as WebhookEventMode,
  jd as WebhookEventType,
  ny as attemptSync,
  Lc as batchResendWebhookAttempt,
  qr as benchmarkGetCapitalProductOwnership,
  Vi as countPdBusinessTableRows,
  ac as countPdCapitalProductApplicationRow,
  dc as countPdCapitalProductOfferRow,
  Zi as countPdCapitalProductRow,
  Xr as createBankAccountInfo,
  Rr as createCapitalProductIntents,
  Yn as createCapitalProducts,
  kr as createOfferedCapitalProductFeeDiscount,
  it as createOrganization,
  rt as createPartnerApiKey,
  _y as createPdWebhookEndpoint,
  cs as createPlaidInvestigation,
  li as createS3OfferCsv,
  Ky as createS3PartnerDashboardTableCsv,
  Oe as deleteBeneficialOwnersId,
  He as deleteBusinessOfficersId,
  N as deleteCashAdvancePausesP1,
  oe as deleteMarketingIntegrationsVendor,
  Bu as deleteMerchantBankExternalAccountsId,
  Mo as deleteMerchantBankRecipientsId,
  Ft as deletePartnerDeleteUsers,
  Wt as deletePartnerSyncWebhooksId,
  ly as deletePdWebhookEndpoint,
  ht as deleteSlackChannelsId,
  jy as getAmazonFundingAccountLock,
  ai as getAnalyticsCubeToken,
  ry as getAttemptSyncMutationOptions,
  Hr as getBankAccountInfos,
  Re as getBanks,
  Wc as getBatchResendWebhookAttemptMutationOptions,
  fr as getBenchmarkGetCapitalProductOwnershipQueryKey,
  Or as getBenchmarkGetCapitalProductOwnershipQueryOptions,
  le as getBeneficialOwners,
  g as getBusinessAnnotations,
  d as getBusinessCores,
  Os as getBusinessIdentities,
  Le as getBusinessOfficers,
  Te as getBusinessTableRowsCard,
  Se as getBusinessTableRowsLoan,
  Ne as getBusinessTableRowsMca,
  Ce as getBusinessTableRowsProductAgnostic,
  xa as getBusinessesDailySalesRecords,
  gi as getBusinessesNotifications,
  Js as getBusinessesProducts,
  sr as getCapitalProductActivities,
  Pr as getCapitalProductIntents,
  dr as getCapitalProductNetTopUpItemization,
  Zn as getCapitalProductOfferCollection,
  Ln as getCapitalProductProspectiveTermsOfService,
  hr as getCapitalProductRepaymentSchedule,
  lr as getCapitalProductStateDisclosure,
  $n as getCapitalProducts,
  Vs as getCards,
  K as getCashAdvanceChargeOffs,
  F as getCashAdvancePauses,
  Hi as getCountPdBusinessTableRowsQueryKey,
  Yi as getCountPdBusinessTableRowsQueryOptions,
  oc as getCountPdCapitalProductApplicationRowQueryKey,
  uc as getCountPdCapitalProductApplicationRowQueryOptions,
  _c as getCountPdCapitalProductOfferRowQueryKey,
  pc as getCountPdCapitalProductOfferRowQueryOptions,
  tc as getCountPdCapitalProductRowQueryKey,
  ec as getCountPdCapitalProductRowQueryOptions,
  vr as getCounteroffer,
  Jr as getCreateBankAccountInfoMutationOptions,
  Fr as getCreateCapitalProductIntentsMutationOptions,
  jn as getCreateCapitalProductsMutationOptions,
  Mr as getCreateOfferedCapitalProductFeeDiscountMutationOptions,
  ct as getCreateOrganizationMutationOptions,
  st as getCreatePartnerApiKeyMutationOptions,
  py as getCreatePdWebhookEndpointMutationOptions,
  ys as getCreatePlaidInvestigationMutationOptions,
  mi as getCreateS3OfferCsvMutationOptions,
  by as getCreateS3PartnerDashboardTableCsvMutationOptions,
  kn as getDataingestionS3DatasetIngestionConfigs,
  pn as getDataingestionS3DatasetIntegrations,
  yn as getDataingestionS3DatasetUploadValidationResults,
  on as getDataingestionS3DatasetUploads,
  fn as getDataingestionS3OrgDatasetIntegrations,
  Un as getDataingestionStripeIngestions,
  Qu as getDebtChecks,
  Du as getDebts,
  he as getDeleteBeneficialOwnersIdMutationOptions,
  Ye as getDeleteBusinessOfficersIdMutationOptions,
  D as getDeleteCashAdvancePausesP1MutationOptions,
  ue as getDeleteMarketingIntegrationsVendorMutationOptions,
  Ru as getDeleteMerchantBankExternalAccountsIdMutationOptions,
  Po as getDeleteMerchantBankRecipientsIdMutationOptions,
  Et as getDeletePartnerDeleteUsersMutationOptions,
  zt as getDeletePartnerSyncWebhooksIdMutationOptions,
  my as getDeletePdWebhookEndpointMutationOptions,
  Kt as getDeleteSlackChannelsIdMutationOptions,
  ra as getDocumentUploadGroup,
  ia as getDocumentUploadList,
  Vu as getFinancialReviews,
  Dr as getFlexLoanMinimumRepaymentSchedule,
  es as getFundingAccounts,
  w as getFutureActivities,
  Xy as getGetAmazonFundingAccountLockQueryKey,
  Jy as getGetAmazonFundingAccountLockQueryOptions,
  oi as getGetAnalyticsCubeTokenQueryKey,
  ui as getGetAnalyticsCubeTokenQueryOptions,
  Yr as getGetBankAccountInfosQueryKey,
  jr as getGetBankAccountInfosQueryOptions,
  Fe as getGetBanksQueryKey,
  Ee as getGetBanksQueryOptions,
  me as getGetBeneficialOwnersQueryKey,
  ge as getGetBeneficialOwnersQueryOptions,
  q as getGetBusinessAnnotationsQueryKey,
  f as getGetBusinessAnnotationsQueryOptions,
  _ as getGetBusinessCoresQueryKey,
  p as getGetBusinessCoresQueryOptions,
  hs as getGetBusinessIdentitiesQueryKey,
  Ks as getGetBusinessIdentitiesQueryOptions,
  We as getGetBusinessOfficersQueryKey,
  ze as getGetBusinessOfficersQueryOptions,
  we as getGetBusinessTableRowsCardQueryKey,
  Qe as getGetBusinessTableRowsCardQueryOptions,
  Ge as getGetBusinessTableRowsLoanQueryKey,
  Ie as getGetBusinessTableRowsLoanQueryOptions,
  De as getGetBusinessTableRowsMcaQueryKey,
  Ae as getGetBusinessTableRowsMcaQueryOptions,
  ve as getGetBusinessTableRowsProductAgnosticQueryKey,
  Ue as getGetBusinessTableRowsProductAgnosticQueryOptions,
  Ba as getGetBusinessesDailySalesRecordsQueryKey,
  Ra as getGetBusinessesDailySalesRecordsQueryOptions,
  qi as getGetBusinessesNotificationsQueryKey,
  fi as getGetBusinessesNotificationsQueryOptions,
  Zs as getGetBusinessesProductsQueryKey,
  ta as getGetBusinessesProductsQueryOptions,
  ar as getGetCapitalProductActivitiesQueryKey,
  or as getGetCapitalProductActivitiesQueryOptions,
  xr as getGetCapitalProductIntentsQueryKey,
  Br as getGetCapitalProductIntentsQueryOptions,
  _r as getGetCapitalProductNetTopUpItemizationQueryKey,
  pr as getGetCapitalProductNetTopUpItemizationQueryOptions,
  tr as getGetCapitalProductOfferCollectionQueryKey,
  er as getGetCapitalProductOfferCollectionQueryOptions,
  Wn as getGetCapitalProductProspectiveTermsOfServiceQueryKey,
  zn as getGetCapitalProductProspectiveTermsOfServiceQueryOptions,
  Kr as getGetCapitalProductRepaymentScheduleQueryKey,
  br as getGetCapitalProductRepaymentScheduleQueryOptions,
  mr as getGetCapitalProductStateDisclosureQueryKey,
  gr as getGetCapitalProductStateDisclosureQueryOptions,
  Vn as getGetCapitalProductsQueryKey,
  Hn as getGetCapitalProductsQueryOptions,
  Hs as getGetCardsQueryKey,
  Ys as getGetCardsQueryOptions,
  b as getGetCashAdvanceChargeOffsQueryKey,
  k as getGetCashAdvanceChargeOffsQueryOptions,
  E as getGetCashAdvancePausesQueryKey,
  C as getGetCashAdvancePausesQueryOptions,
  Ur as getGetCounterofferQueryKey,
  Nr as getGetCounterofferQueryOptions,
  Mn as getGetDataingestionS3DatasetIngestionConfigsQueryKey,
  Pn as getGetDataingestionS3DatasetIngestionConfigsQueryOptions,
  ln as getGetDataingestionS3DatasetIntegrationsQueryKey,
  mn as getGetDataingestionS3DatasetIntegrationsQueryOptions,
  dn as getGetDataingestionS3DatasetUploadValidationResultsQueryKey,
  _n as getGetDataingestionS3DatasetUploadValidationResultsQueryOptions,
  un as getGetDataingestionS3DatasetUploadsQueryKey,
  cn as getGetDataingestionS3DatasetUploadsQueryOptions,
  On as getGetDataingestionS3OrgDatasetIntegrationsQueryKey,
  hn as getGetDataingestionS3OrgDatasetIntegrationsQueryOptions,
  Nn as getGetDataingestionStripeIngestionsQueryKey,
  Dn as getGetDataingestionStripeIngestionsQueryOptions,
  Lu as getGetDebtChecksQueryKey,
  Wu as getGetDebtChecksQueryOptions,
  Au as getGetDebtsQueryKey,
  Su as getGetDebtsQueryOptions,
  sa as getGetDocumentUploadGroupQueryKey,
  aa as getGetDocumentUploadGroupQueryOptions,
  ca as getGetDocumentUploadListQueryKey,
  ya as getGetDocumentUploadListQueryOptions,
  Hu as getGetFinancialReviewsQueryKey,
  Yu as getGetFinancialReviewsQueryOptions,
  Ar as getGetFlexLoanMinimumRepaymentScheduleQueryKey,
  Sr as getGetFlexLoanMinimumRepaymentScheduleQueryOptions,
  ns as getGetFundingAccountsQueryKey,
  rs as getGetFundingAccountsQueryOptions,
  Q as getGetFutureActivitiesQueryKey,
  L as getGetFutureActivitiesQueryOptions,
  ha as getGetLiensQueryKey,
  Ka as getGetLiensQueryOptions,
  ce as getGetMarketingGtmToolkitQueryKey,
  ye as getGetMarketingGtmToolkitQueryOptions,
  ne as getGetMarketingIntegrationsQueryKey,
  re as getGetMarketingIntegrationsQueryOptions,
  _e as getGetMarketingParagonTokenQueryKey,
  pe as getGetMarketingParagonTokenQueryOptions,
  za as getGetMerchantBankAccountsIdBalancesQueryKey,
  $a as getGetMerchantBankAccountsIdBalancesQueryOptions,
  Qa as getGetMerchantBankAccountsIdQueryKey,
  La as getGetMerchantBankAccountsIdQueryOptions,
  Ha as getGetMerchantBankAccountsQueryKey,
  Ya as getGetMerchantBankAccountsQueryOptions,
  wy as getGetMerchantBankApplicationsIdQueryKey,
  Qy as getGetMerchantBankApplicationsIdQueryOptions,
  Wy as getGetMerchantBankApplicationsQueryKey,
  zy as getGetMerchantBankApplicationsQueryOptions,
  Vo as getGetMerchantBankCardAccessTokenQueryKey,
  Ho as getGetMerchantBankCardAccessTokenQueryOptions,
  Jo as getGetMerchantBankCardCardholderAccessTokenQueryKey,
  Zo as getGetMerchantBankCardCardholderAccessTokenQueryOptions,
  Qo as getGetMerchantBankCardCardholdersQueryKey,
  Lo as getGetMerchantBankCardCardholdersQueryOptions,
  au as getGetMerchantBankCardDisputesQueryKey,
  ou as getGetMerchantBankCardDisputesQueryOptions,
  Do as getGetMerchantBankCardsQueryKey,
  Ao as getGetMerchantBankCardsQueryOptions,
  qu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryKey,
  fu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryOptions,
  Pu as getGetMerchantBankExternalAccountsIdQueryKey,
  xu as getGetMerchantBankExternalAccountsIdQueryOptions,
  lu as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryKey,
  mu as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryOptions,
  hu as getGetMerchantBankExternalAccountsQueryKey,
  Ku as getGetMerchantBankExternalAccountsQueryOptions,
  lo as getGetMerchantBankInterestDetailsQueryKey,
  mo as getGetMerchantBankInterestDetailsQueryOptions,
  wd as getGetMerchantBankOffersQueryKey,
  Qd as getGetMerchantBankOffersQueryOptions,
  bo as getGetMerchantBankRecipientsIdQueryKey,
  ko as getGetMerchantBankRecipientsIdQueryOptions,
  qo as getGetMerchantBankRecipientsQueryKey,
  fo as getGetMerchantBankRecipientsQueryOptions,
  eo as getGetMerchantBankTransactionsIdQueryKey,
  no as getGetMerchantBankTransactionsIdQueryOptions,
  so as getGetMerchantBankTransactionsQueryKey,
  ao as getGetMerchantBankTransactionsQueryOptions,
  uo as getGetMerchantBankTransferFeesQueryKey,
  io as getGetMerchantBankTransferFeesQueryOptions,
  yo as getGetMerchantBankTransferLimitsQueryKey,
  _o as getGetMerchantBankTransferLimitsQueryOptions,
  td as getGetNaicsQueryKey,
  ed as getGetNaicsQueryOptions,
  ei as getGetOfficerSubsidiaryRecordsQueryKey,
  ni as getGetOfficerSubsidiaryRecordsQueryOptions,
  wn as getGetOnboardingBusinessRequirementsQueryKey,
  Qn as getGetOnboardingBusinessRequirementsQueryOptions,
  qs as getGetOnboardingInfoNeededQueryKey,
  fs as getGetOnboardingInfoNeededQueryOptions,
  ls as getGetOnboardingStatesQueryKey,
  ms as getGetOnboardingStatesQueryOptions,
  pt as getGetOrganizationQueryKey,
  lt as getGetOrganizationQueryOptions,
  Me as getGetOwnerIsBeneficialOwnerQueryKey,
  Pe as getGetOwnerIsBeneficialOwnerQueryOptions,
  Ps as getGetOwnersQueryKey,
  xs as getGetOwnersQueryOptions,
  ud as getGetPADAgreementQueryKey,
  id as getGetPADAgreementQueryOptions,
  Ua as getGetParafinderBusinessTypesQueryKey,
  Na as getGetParafinderBusinessTypesQueryOptions,
  Ia as getGetParafinderCapitalInfoQueryKey,
  Ta as getGetParafinderCapitalInfoQueryOptions,
  Ea as getGetParafinderQueryKey,
  Ca as getGetParafinderQueryOptions,
  Aa as getGetParafinderSummaryQueryKey,
  Sa as getGetParafinderSummaryQueryOptions,
  et as getGetPartnerApiKeyQueryKey,
  nt as getGetPartnerApiKeyQueryOptions,
  Dt as getGetPartnerConfigsQueryKey,
  At as getGetPartnerConfigsQueryOptions,
  vt as getGetPartnerPublicConfigsQueryKey,
  Ut as getGetPartnerPublicConfigsQueryOptions,
  Tt as getGetPartnerSyncWebhooksQueryKey,
  wt as getGetPartnerSyncWebhooksQueryOptions,
  kt as getGetPartnerUsersQueryKey,
  Mt as getGetPartnerUsersQueryOptions,
  Ay as getGetPartnerWidgetConfigsQueryKey,
  Sy as getGetPartnerWidgetConfigsQueryOptions,
  B as getGetPartnersDoordashAdminUsersQueryKey,
  R as getGetPartnersDoordashAdminUsersQueryOptions,
  z as getGetPartnersQueryKey,
  $ as getGetPartnersQueryOptions,
  j as getGetPartnersUnderwritingQueryKey,
  X as getGetPartnersUnderwritingQueryOptions,
  Kc as getGetPdOfferUrlQueryKey,
  bc as getGetPdOfferUrlQueryOptions,
  Bc as getGetPdUtilityInfoQueryKey,
  Rc as getGetPdUtilityInfoQueryOptions,
  us as getGetPlaidInvestigationsQueryKey,
  is as getGetPlaidInvestigationsQueryOptions,
  I as getGetRepaymentPlanParametersQueryKey,
  T as getGetRepaymentPlanParametersQueryOptions,
  _a as getGetRestrictionQueryKey,
  pa as getGetRestrictionQueryOptions,
  qa as getGetRestrictionsDetailsQueryKey,
  fa as getGetRestrictionsDetailsQueryOptions,
  wr as getGetRunOfacScreeningResultsQueryKey,
  Qr as getGetRunOfacScreeningResultsQueryOptions,
  My as getGetRutterConnectionsQueryKey,
  Py as getGetRutterConnectionsQueryOptions,
  Cy as getGetSardineSessionIdQueryKey,
  vy as getGetSardineSessionIdQueryOptions,
  ft as getGetSlackChannelsQueryKey,
  Ot as getGetSlackChannelsQueryOptions,
  Xt as getGetStatementsV2QueryKey,
  Jt as getGetStatementsV2QueryOptions,
  iy as getGetSyncAndMaybeMarkFailedMutationOptions,
  ay as getGetSyncQueryKey,
  oy as getGetSyncQueryOptions,
  Od as getGetTemplateMockQueryKey,
  hd as getGetTemplateMockQueryOptions,
  Pd as getGetTemplateSnapshotQueryKey,
  xd as getGetTemplateSnapshotQueryOptions,
  bd as getGetTemplateSnapshotsQueryKey,
  kd as getGetTemplateSnapshotsQueryOptions,
  Rd as getGetTemplateTypesQueryKey,
  Fd as getGetTemplateTypesQueryOptions,
  _d as getGetTemplatesQueryKey,
  pd as getGetTemplatesQueryOptions,
  $r as getGetTermLoanProspectiveRepaymentsQueryKey,
  Vr as getGetTermLoanProspectiveRepaymentsQueryOptions,
  Oa as getLiens,
  dt as getLinkPartnerMutationOptions,
  ci as getListApiRequestsQueryKey,
  yi as getListApiRequestsQueryOptions,
  en as getListDatasetUploadsQueryKey,
  nn as getListDatasetUploadsQueryOptions,
  sn as getListManualDatasetUploadValidationResultsQueryKey,
  an as getListManualDatasetUploadValidationResultsQueryOptions,
  ot as getListOrganizationsQueryKey,
  ut as getListOrganizationsQueryOptions,
  Mi as getListPdBusinessDetailsApplicationsQueryKey,
  Pi as getListPdBusinessDetailsApplicationsQueryOptions,
  Bi as getListPdBusinessDetailsBankInfoQueryKey,
  Ri as getListPdBusinessDetailsBankInfoQueryOptions,
  Ei as getListPdBusinessDetailsBusinessInfoQueryKey,
  Ci as getListPdBusinessDetailsBusinessInfoQueryOptions,
  Ai as getListPdBusinessDetailsFundedProductDetailsQueryKey,
  Si as getListPdBusinessDetailsFundedProductDetailsQueryOptions,
  Ii as getListPdBusinessDetailsFundedProductPaymentProgressQueryKey,
  Ti as getListPdBusinessDetailsFundedProductPaymentProgressQueryOptions,
  Ui as getListPdBusinessDetailsFundedProductsQueryKey,
  Ni as getListPdBusinessDetailsFundedProductsQueryOptions,
  Qi as getListPdBusinessDetailsOffersQueryKey,
  Li as getListPdBusinessDetailsOffersQueryOptions,
  zi as getListPdBusinessDetailsOwnerInfoQueryKey,
  $i as getListPdBusinessDetailsOwnerInfoQueryOptions,
  Xi as getListPdBusinessTableRowsQueryKey,
  Ji as getListPdBusinessTableRowsQueryOptions,
  cc as getListPdCapitalProductApplicationRowsQueryKey,
  yc as getListPdCapitalProductApplicationRowsQueryOptions,
  mc as getListPdCapitalProductOfferRowsQueryKey,
  gc as getListPdCapitalProductOfferRowsQueryOptions,
  rc as getListPdCapitalProductRowsQueryKey,
  sc as getListPdCapitalProductRowsQueryOptions,
  Yc as getListPdCrmDeliveryAttemptsQueryKey,
  jc as getListPdCrmDeliveryAttemptsQueryOptions,
  ty as getListPdCrmEventInfoQueryKey,
  ey as getListPdCrmEventInfoQueryOptions,
  $c as getListPdCrmEventsQueryKey,
  Vc as getListPdCrmEventsQueryOptions,
  Mc as getListPdCrmIntegrationsQueryKey,
  Pc as getListPdCrmIntegrationsQueryOptions,
  fc as getListPdSupportInfoQueryKey,
  Oc as getListPdSupportInfoQueryOptions,
  yy as getListPdWebhookEndpointsQueryKey,
  dy as getListPdWebhookEndpointsQueryOptions,
  Ac as getListPdWebhookLogsDeliveryAttemptsQueryKey,
  Sc as getListPdWebhookLogsDeliveryAttemptsQueryOptions,
  Uc as getListPdWebhookLogsEventInfoQueryKey,
  Nc as getListPdWebhookLogsEventInfoQueryOptions,
  Ec as getListPdWebhookLogsEventsQueryKey,
  Cc as getListPdWebhookLogsEventsQueryOptions,
  _i as getListS3OfferCsvsQueryKey,
  pi as getListS3OfferCsvsQueryOptions,
  Oy as getListS3PartnerDashboardTableCsvsQueryKey,
  hy as getListS3PartnerDashboardTableCsvsQueryOptions,
  Wd as getListSardineDetailsQueryKey,
  zd as getListSardineDetailsQueryOptions,
  Cn as getListVersionedSchemasQueryKey,
  vn as getListVersionedSchemasQueryOptions,
  yd as getMarkPADAgreementSignedMutationOptions,
  ie as getMarketingGtmToolkit,
  ee as getMarketingIntegrations,
  de as getMarketingParagonToken,
  Va as getMerchantBankAccounts,
  wa as getMerchantBankAccountsId,
  Wa as getMerchantBankAccountsIdBalances,
  Ly as getMerchantBankApplications,
  Ty as getMerchantBankApplicationsId,
  $o as getMerchantBankCardAccessToken,
  Xo as getMerchantBankCardCardholderAccessToken,
  wo as getMerchantBankCardCardholders,
  su as getMerchantBankCardDisputes,
  No as getMerchantBankCards,
  Ou as getMerchantBankExternalAccounts,
  Mu as getMerchantBankExternalAccountsId,
  gu as getMerchantBankExternalAccountsIdPlaidLinkToken,
  pu as getMerchantBankExternalAccountsPlaidLinkToken,
  po as getMerchantBankInterestDetails,
  Td as getMerchantBankOffers,
  go as getMerchantBankRecipients,
  Ko as getMerchantBankRecipientsId,
  ro as getMerchantBankTransactions,
  to as getMerchantBankTransactionsId,
  oo as getMerchantBankTransferFees,
  co as getMerchantBankTransferLimits,
  Ic as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey,
  Tc as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryOptions,
  Zy as getNaics,
  ti as getOfficerSubsidiaryRecords,
  Tn as getOnboardingBusinessRequirements,
  gs as getOnboardingInfoNeeded,
  ps as getOnboardingStates,
  _t as getOrganization,
  ke as getOwnerIsBeneficialOwner,
  Ms as getOwners,
  od as getPADAgreement,
  Fa as getParafinder,
  va as getParafinderBusinessTypes,
  Ga as getParafinderCapitalInfo,
  Da as getParafinderSummary,
  tt as getPartnerApiKey,
  Nt as getPartnerConfigs,
  Ct as getPartnerPublicConfigs,
  It as getPartnerSyncWebhooks,
  bt as getPartnerUsers,
  Dy as getPartnerWidgetConfigs,
  W as getPartners,
  x as getPartnersDoordashAdminUsers,
  Y as getPartnersUnderwriting,
  be as getPatchBeneficialOwnersIdMutationOptions,
  ks as getPatchBusinessIdentitiesIdMutationOptions,
  Xe as getPatchBusinessOfficersIdMutationOptions,
  Cr as getPatchCapitalProductIntentsMutationOptions,
  Jn as getPatchCapitalProductsMutationOptions,
  S as getPatchCashAdvancePausesP1MutationOptions,
  Wr as getPatchClearWatchlistHitsIdMutationOptions,
  In as getPatchDataingestionStripeIngestionsMutationOptions,
  Ts as getPatchFlexLoanAgeMutationOptions,
  Ws as getPatchFlexLoanFundMutationOptions,
  Qs as getPatchFlexLoanOwnershipMutationOptions,
  ka as getPatchLiensIdMutationOptions,
  To as getPatchMerchantBankCardCardholdersIdMutationOptions,
  Eu as getPatchMerchantBankExternalAccountsIdMutationOptions,
  Nu as getPatchMerchantBankExternalBankTransfersIdMutationOptions,
  Vd as getPatchMerchantBankMerchantConfigMutationOptions,
  Uo as getPatchMerchantBankRecipientTransfersIdMutationOptions,
  Bo as getPatchMerchantBankRecipientsIdMutationOptions,
  Id as getPatchMfaExtendSessionMutationOptions,
  Be as getPatchOwnerIsBeneficialOwnerMutationOptions,
  Rs as getPatchOwnersIdMutationOptions,
  Gt as getPatchPartnerConfigsIdMutationOptions,
  Vt as getPatchPartnerSyncWebhooksIdMutationOptions,
  Rt as getPatchPartnerUsersMutationOptions,
  Iy as getPatchPartnerWidgetConfigsMutationOptions,
  Z as getPatchPartnersIdMutationOptions,
  _s as getPatchPersonaFallbackInquiryMutationOptions,
  qd as getPatchTemplatesMutationOptions,
  hc as getPdOfferUrl,
  xc as getPdUtilityInfo,
  os as getPlaidInvestigations,
  Ns as getPostAmazonRedirectUrlMutationOptions,
  Yt as getPostAuthTokensMutationOptions,
  fe as getPostBeneficialOwnersMutationOptions,
  h as getPostBusinessAnnotationsMutationOptions,
  na as getPostBusinessAuthLinkMutationOptions,
  m as getPostBusinessCoresMutationOptions,
  Es as getPostBusinessMutationOptions,
  Ve as getPostBusinessOfficersMutationOptions,
  hi as getPostBusinessesNotificationsMutationOptions,
  ir as getPostCapitalProductApplicationMutationOptions,
  Gs as getPostCapitalProductBusinessMutationOptions,
  yr as getPostCapitalProductNetTopUpMutationOptions,
  rr as getPostCapitalProductOfferCollectionMutationOptions,
  $u as getPostCardCloseAccountMutationOptions,
  Xs as getPostCardsMutationOptions,
  P as getPostCashAdvanceChargeOffsMutationOptions,
  U as getPostCashAdvancePausesMutationOptions,
  Ze as getPostDataingestionOneschemaGenerateJwtMutationOptions,
  qn as getPostDataingestionS3DatasetIntegrationsMutationOptions,
  bn as getPostDataingestionS3OrgDatasetIntegrationsMutationOptions,
  Fn as getPostDataingestionS3RunDatasetIngestionMutationOptions,
  Sn as getPostDataingestionStripeIngestionsMutationOptions,
  wu as getPostDebtsCheckCompleteMutationOptions,
  Iu as getPostDebtsMutationOptions,
  ua as getPostDocumentUploadGroupMutationOptions,
  Xu as getPostFinancialReviewsMutationOptions,
  Dd as getPostForgiveCapitalFeeMutationOptions,
  as as getPostFundingAccountsMutationOptions,
  Ud as getPostManualRepaymentMutationOptions,
  Fy as getPostMarkAccountingIntegrationSeenMutationOptions,
  ae as getPostMarketingIntegrationsMutationOptions,
  te as getPostMarketingReviewMutationOptions,
  Xa as getPostMerchantBankAccountsMutationOptions,
  Vy as getPostMerchantBankApplicationsMutationOptions,
  vs as getPostMerchantBankBusinessMutationOptions,
  zo as getPostMerchantBankCardActivateMutationOptions,
  jo as getPostMerchantBankCardApplePayMutationOptions,
  _u as getPostMerchantBankCardDisputeChargebackCreditMutationOptions,
  yu as getPostMerchantBankCardDisputeStateTransitionMutationOptions,
  iu as getPostMerchantBankCardDisputesMutationOptions,
  ru as getPostMerchantBankCardGooglePayMutationOptions,
  eu as getPostMerchantBankCardStateTransitionMutationOptions,
  Go as getPostMerchantBankCardsMutationOptions,
  ku as getPostMerchantBankExternalAccountsMutationOptions,
  vu as getPostMerchantBankExternalBankTransfersMutationOptions,
  Za as getPostMerchantBankInnerTransfersMutationOptions,
  Co as getPostMerchantBankRecipientTransfersMutationOptions,
  Fo as getPostMerchantBankRecipientsIdPaymentRailsMutationOptions,
  ho as getPostMerchantBankRecipientsMutationOptions,
  rd as getPostMfaOtpSendPersonidPhonenumbertypeMutationOptions,
  ad as getPostMfaOtpVerifyPersonidPhonenumbertypeMutationOptions,
  Sd as getPostMfaVerifyAccountDetailsMutationOptions,
  si as getPostOfficerSubsidiaryRecordsMutationOptions,
  Pa as getPostOptInMutationOptions,
  Lt as getPostPartnerSyncWebhooksMutationOptions,
  xt as getPostPartnerUsersMutationOptions,
  H as getPostPartnersMutationOptions,
  ma as getPostRestrictionMutationOptions,
  By as getPostRutterConnectionsMutationOptions,
  Ny as getPostSardineSessionIdMutationOptions,
  $s as getPostSetupAmazonTopUpMutationOptions,
  gt as getPostSlackAuthorizeMutationOptions,
  As as getPostSpdMutationOptions,
  Zu as getPostTaxRecordsMutationOptions,
  Cd as getPostTemplateTypesMutationOptions,
  md as getPostTemplatesMutationOptions,
  bi as getPostTermsOfServiceMarkShownMutationOptions,
  Ir as getPostTriggerRunCelticsChecklistMutationOptions,
  Yy as getPostWebhooksPlaidGeneralMutationOptions,
  Bn as getPutDataingestionS3DatasetIngestionConfigsMutationOptions,
  G as getRepaymentPlanParameters,
  Jc as getResendCrmAttemptMutationOptions,
  Qc as getResendWebhookAttemptMutationOptions,
  da as getRestriction,
  ga as getRestrictionsDetails,
  Tr as getRunOfacScreeningResults,
  ky as getRutterConnections,
  Ey as getSardineSessionId,
  qt as getSlackChannels,
  lO as getStatement,
  jt as getStatementsV2,
  sy as getSync,
  uy as getSyncAndMaybeMarkFailed,
  fd as getTemplateMock,
  Md as getTemplateSnapshot,
  Kd as getTemplateSnapshots,
  Bd as getTemplateTypes,
  dd as getTemplates,
  zr as getTermLoanProspectiveRepayments,
  ts as getUpdateBankAccountInfoMutationOptions,
  qy as getUpdatePdWebhookEndpointMutationOptions,
  yt as linkPartner,
  ii as listApiRequests,
  tn as listDatasetUploads,
  rn as listManualDatasetUploadValidationResults,
  at as listOrganizations,
  ki as listPdBusinessDetailsApplications,
  xi as listPdBusinessDetailsBankInfo,
  Fi as listPdBusinessDetailsBusinessInfo,
  Di as listPdBusinessDetailsFundedProductDetails,
  Gi as listPdBusinessDetailsFundedProductPaymentProgress,
  vi as listPdBusinessDetailsFundedProducts,
  wi as listPdBusinessDetailsOffers,
  Wi as listPdBusinessDetailsOwnerInfo,
  ji as listPdBusinessTableRows,
  ic as listPdCapitalProductApplicationRows,
  lc as listPdCapitalProductOfferRows,
  nc as listPdCapitalProductRows,
  Hc as listPdCrmDeliveryAttempts,
  Zc as listPdCrmEventInfo,
  zc as listPdCrmEvents,
  kc as listPdCrmIntegrations,
  qc as listPdSupportInfo,
  cy as listPdWebhookEndpoints,
  Dc as listPdWebhookLogsDeliveryAttempts,
  vc as listPdWebhookLogsEventInfo,
  Fc as listPdWebhookLogsEvents,
  di as listS3OfferCsvs,
  fy as listS3PartnerDashboardTableCsvs,
  Ld as listSardineDetails,
  En as listVersionedSchemas,
  cd as markPADAgreementSigned,
  Gc as multiWebhookPdWebhookLogsDeliveryAttempts,
  Ke as patchBeneficialOwnersId,
  bs as patchBusinessIdentitiesId,
  je as patchBusinessOfficersId,
  Er as patchCapitalProductIntents,
  Xn as patchCapitalProducts,
  A as patchCashAdvancePausesP1,
  Lr as patchClearWatchlistHitsId,
  Gn as patchDataingestionStripeIngestions,
  Is as patchFlexLoanAge,
  Ls as patchFlexLoanFund,
  ws as patchFlexLoanOwnership,
  ba as patchLiensId,
  Io as patchMerchantBankCardCardholdersId,
  Fu as patchMerchantBankExternalAccountsId,
  Uu as patchMerchantBankExternalBankTransfersId,
  $d as patchMerchantBankMerchantConfig,
  vo as patchMerchantBankRecipientTransfersId,
  xo as patchMerchantBankRecipientsId,
  Gd as patchMfaExtendSession,
  xe as patchOwnerIsBeneficialOwner,
  Bs as patchOwnersId,
  St as patchPartnerConfigsId,
  $t as patchPartnerSyncWebhooksId,
  Bt as patchPartnerUsers,
  Gy as patchPartnerWidgetConfigs,
  J as patchPartnersId,
  ds as patchPersonaFallbackInquiry,
  gd as patchTemplates,
  Us as postAmazonRedirectUrl,
  Ht as postAuthTokens,
  qe as postBeneficialOwners,
  Fs as postBusiness,
  O as postBusinessAnnotations,
  ea as postBusinessAuthLink,
  l as postBusinessCores,
  $e as postBusinessOfficers,
  Oi as postBusinessesNotifications,
  ur as postCapitalProductApplication,
  Ss as postCapitalProductBusiness,
  cr as postCapitalProductNetTopUp,
  nr as postCapitalProductOfferCollection,
  zu as postCardCloseAccount,
  js as postCards,
  M as postCashAdvanceChargeOffs,
  v as postCashAdvancePauses,
  Je as postDataingestionOneschemaGenerateJwt,
  gn as postDataingestionS3DatasetIntegrations,
  Kn as postDataingestionS3OrgDatasetIntegrations,
  Rn as postDataingestionS3RunDatasetIngestion,
  An as postDataingestionStripeIngestions,
  Gu as postDebts,
  Tu as postDebtsCheckComplete,
  oa as postDocumentUploadGroup,
  ju as postFinancialReviews,
  Nd as postForgiveCapitalFee,
  ss as postFundingAccounts,
  vd as postManualRepayment,
  Ry as postMarkAccountingIntegrationSeen,
  se as postMarketingIntegrations,
  Zt as postMarketingReview,
  ja as postMerchantBankAccounts,
  $y as postMerchantBankApplications,
  Cs as postMerchantBankBusiness,
  Wo as postMerchantBankCardActivate,
  Yo as postMerchantBankCardApplePay,
  du as postMerchantBankCardDisputeChargebackCredit,
  cu as postMerchantBankCardDisputeStateTransition,
  uu as postMerchantBankCardDisputes,
  nu as postMerchantBankCardGooglePay,
  tu as postMerchantBankCardStateTransition,
  So as postMerchantBankCards,
  bu as postMerchantBankExternalAccounts,
  Cu as postMerchantBankExternalBankTransfers,
  Ja as postMerchantBankInnerTransfers,
  Eo as postMerchantBankRecipientTransfers,
  Oo as postMerchantBankRecipients,
  Ro as postMerchantBankRecipientsIdPaymentRails,
  nd as postMfaOtpSendPersonidPhonenumbertype,
  sd as postMfaOtpVerifyPersonidPhonenumbertype,
  Ad as postMfaVerifyAccountDetails,
  ri as postOfficerSubsidiaryRecords,
  Ma as postOptIn,
  Qt as postPartnerSyncWebhooks,
  Pt as postPartnerUsers,
  V as postPartners,
  la as postRestriction,
  xy as postRutterConnections,
  Uy as postSardineSessionId,
  zs as postSetupAmazonTopUp,
  mt as postSlackAuthorize,
  Ds as postSpd,
  Ju as postTaxRecords,
  Ed as postTemplateTypes,
  ld as postTemplates,
  Ki as postTermsOfServiceMarkShown,
  Gr as postTriggerRunCelticsChecklist,
  Hy as postWebhooksPlaidGeneral,
  jO as publicApiPaths,
  xn as putDataingestionS3DatasetIngestionConfigs,
  Xc as resendCrmAttempt,
  wc as resendWebhookAttempt,
  Zr as updateBankAccountInfo,
  gy as updatePdWebhookEndpoint,
  IO as useActivateCard,
  DO as useAmazonRPCPreLogin,
  TO as useAppleWallet,
  zO as useAttemptAmazonBankVerification,
  kf as useAttemptSync,
  ff as useBatchResendWebhookAttempt,
  Lm as useBenchmarkGetCapitalProductOwnership,
  tf as useCountPdBusinessTableRows,
  sf as useCountPdCapitalProductApplicationRow,
  of as useCountPdCapitalProductOfferRow,
  nf as useCountPdCapitalProductRow,
  ng as useCreateBankAccountInfo,
  BO as useCreateBeneficialOwner,
  Vm as useCreateCapitalProductIntents,
  Nm as useCreateCapitalProducts,
  zm as useCreateOfferedCapitalProductFeeDiscount,
  Fl as useCreateOrganization,
  Bl as useCreatePartnerApiKey,
  Bf as useCreatePdWebhookEndpoint,
  ug as useCreatePlaidInvestigation,
  $O as useCreatePlaidLinkage,
  Qq as useCreateS3OfferCsv,
  Cf as useCreateS3PartnerDashboardTableCsv,
  NO as useCreateUnderwritingAttempt,
  vO as useCreateUnverifiedAmazonFundingAccount,
  CO as useCreateUnverifiedFundingAccount,
  WO as useCreateUnverifiedFundingAccountFromPlaidLinkage,
  VO as useCreateZendeskTicket,
  FO as useDeleteBeneficialOwner,
  nm as useDeleteBeneficialOwnersId,
  pm as useDeleteBusinessOfficersId,
  fl as useDeleteCashAdvancePausesP1,
  Xl as useDeleteMarketingIntegrationsVendor,
  Pq as useDeleteMerchantBankExternalAccountsId,
  eq as useDeleteMerchantBankRecipientsId,
  Gl as useDeletePartnerDeleteUsers,
  Wl as useDeletePartnerSyncWebhooksId,
  Rf as useDeletePdWebhookEndpoint,
  Nl as useDeleteSlackChannelsId,
  GO as useDisputeTransaction,
  LO as useFailMicrodeposit,
  Lf as useGetAmazonFundingAccountLock,
  Iq as useGetAnalyticsCubeToken,
  eg as useGetBankAccountInfos,
  om as useGetBanks,
  tm as useGetBeneficialOwners,
  dl as useGetBusinessAnnotations,
  cl as useGetBusinessCores,
  dg as useGetBusinessIdentities,
  dm as useGetBusinessOfficers,
  ym as useGetBusinessTableRowsCard,
  cm as useGetBusinessTableRowsLoan,
  im as useGetBusinessTableRowsMca,
  um as useGetBusinessTableRowsProductAgnostic,
  Sg as useGetBusinessesDailySalesRecords,
  Lq as useGetBusinessesNotifications,
  xg as useGetBusinessesProducts,
  Gm as useGetCapitalProductActivities,
  $m as useGetCapitalProductIntents,
  wm as useGetCapitalProductNetTopUpItemization,
  Am as useGetCapitalProductOfferCollection,
  vm as useGetCapitalProductProspectiveTermsOfService,
  Wm as useGetCapitalProductRepaymentSchedule,
  Qm as useGetCapitalProductStateDisclosure,
  Um as useGetCapitalProducts,
  OO as useGetCardAccessToken,
  _O as useGetCardApplication,
  fO as useGetCardBalance,
  MO as useGetCardLifecycleState,
  PO as useGetCardOffer,
  dO as useGetCardOffers,
  qO as useGetCardRepayments,
  mO as useGetCardTransactions,
  pO as useGetCardholder,
  Mg as useGetCards,
  pl as useGetCashAdvanceChargeOffs,
  gl as useGetCashAdvancePauses,
  Ym as useGetCounteroffer,
  Mm as useGetDataingestionS3DatasetIngestionConfigs,
  hm as useGetDataingestionS3DatasetIntegrations,
  Om as useGetDataingestionS3DatasetUploadValidationResults,
  fm as useGetDataingestionS3DatasetUploads,
  bm as useGetDataingestionS3OrgDatasetIntegrations,
  Rm as useGetDataingestionStripeIngestions,
  vq as useGetDebtChecks,
  Fq as useGetDebts,
  Rg as useGetDocumentUploadGroup,
  Eg as useGetDocumentUploadList,
  Nq as useGetFinancialReviews,
  jm as useGetFlexLoanMinimumRepaymentSchedule,
  sg as useGetFundingAccounts,
  Kl as useGetFutureActivities,
  bO as useGetIsWhitelistedForEarlyManualPayment,
  Ng as useGetLiens,
  Jl as useGetMarketingGtmToolkit,
  Yl as useGetMarketingIntegrations,
  Zl as useGetMarketingParagonToken,
  Wg as useGetMerchantBankAccounts,
  Qg as useGetMerchantBankAccountsId,
  Lg as useGetMerchantBankAccountsIdBalances,
  Tf as useGetMerchantBankApplications,
  If as useGetMerchantBankApplicationsId,
  dq as useGetMerchantBankCardAccessToken,
  pq as useGetMerchantBankCardCardholderAccessToken,
  cq as useGetMerchantBankCardCardholders,
  gq as useGetMerchantBankCardDisputes,
  oq as useGetMerchantBankCards,
  bq as useGetMerchantBankExternalAccounts,
  Mq as useGetMerchantBankExternalAccountsId,
  Kq as useGetMerchantBankExternalAccountsIdPlaidLinkToken,
  hq as useGetMerchantBankExternalAccountsPlaidLinkToken,
  Xg as useGetMerchantBankInterestDetails,
  uO as useGetMerchantBankOffers,
  Jg as useGetMerchantBankRecipients,
  tq as useGetMerchantBankRecipientsId,
  Hg as useGetMerchantBankTransactions,
  Vg as useGetMerchantBankTransactionsId,
  Yg as useGetMerchantBankTransferFees,
  jg as useGetMerchantBankTransferLimits,
  hO as useGetMicrodepositLinkToken,
  Wf as useGetNaics,
  Sq as useGetOfficerSubsidiaryRecords,
  Cm as useGetOnboardingBusinessRequirements,
  yg as useGetOnboardingInfoNeeded,
  cg as useGetOnboardingStates,
  xO as useGetOptIns,
  kO as useGetOptOutEligibility,
  Cl as useGetOrganization,
  sm as useGetOwnerIsBeneficialOwner,
  pg as useGetOwners,
  Vf as useGetPADAgreement,
  Gg as useGetParafinder,
  Ig as useGetParafinderBusinessTypes,
  wg as useGetParafinderCapitalInfo,
  Tg as useGetParafinderSummary,
  xl as useGetPartnerApiKey,
  Tl as useGetPartnerConfigs,
  Il as useGetPartnerPublicConfigs,
  Ql as useGetPartnerSyncWebhooks,
  Dl as useGetPartnerUsers,
  Sf as useGetPartnerWidgetConfigs,
  bl as useGetPartners,
  ml as useGetPartnersDoordashAdminUsers,
  Ml as useGetPartnersUnderwriting,
  yf as useGetPdOfferUrl,
  _f as useGetPdUtilityInfo,
  gO as useGetPendingCardTransactions,
  og as useGetPlaidInvestigations,
  yO as useGetPlaidLinkToken,
  hl as useGetRepaymentPlanParameters,
  Cg as useGetRestriction,
  Ug as useGetRestrictionsDetails,
  Jm as useGetRunOfacScreeningResults,
  vf as useGetRutterConnections,
  Df as useGetSardineSessionId,
  KO as useGetShowTopUpOffer,
  Ul as useGetSlackChannels,
  Vl as useGetStatementsV2,
  Mf as useGetSync,
  Pf as useGetSyncAndMaybeMarkFailed,
  Jf as useGetTemplateMock,
  tO as useGetTemplateSnapshot,
  Zf as useGetTemplateSnapshots,
  eO as useGetTemplateTypes,
  Yf as useGetTemplates,
  tg as useGetTermLoanProspectiveRepayments,
  wO as useGooglePay,
  QO as useInitiateMicrodeposit,
  El as useLinkPartner,
  Tq as useListApiRequests,
  gm as useListDatasetUploads,
  qm as useListManualDatasetUploadValidationResults,
  Rl as useListOrganizations,
  $q as useListPdBusinessDetailsApplications,
  Vq as useListPdBusinessDetailsBankInfo,
  Hq as useListPdBusinessDetailsBusinessInfo,
  jq as useListPdBusinessDetailsFundedProductDetails,
  Xq as useListPdBusinessDetailsFundedProductPaymentProgress,
  Yq as useListPdBusinessDetailsFundedProducts,
  Jq as useListPdBusinessDetailsOffers,
  Zq as useListPdBusinessDetailsOwnerInfo,
  ef as useListPdBusinessTableRows,
  af as useListPdCapitalProductApplicationRows,
  uf as useListPdCapitalProductOfferRows,
  rf as useListPdCapitalProductRows,
  hf as useListPdCrmDeliveryAttempts,
  bf as useListPdCrmEventInfo,
  Of as useListPdCrmEvents,
  df as useListPdCrmIntegrations,
  cf as useListPdSupportInfo,
  xf as useListPdWebhookEndpoints,
  mf as useListPdWebhookLogsDeliveryAttempts,
  lf as useListPdWebhookLogsEventInfo,
  pf as useListPdWebhookLogsEvents,
  wq as useListS3OfferCsvs,
  Ef as useListS3PartnerDashboardTableCsvs,
  iO as useListSardineDetails,
  Bm as useListVersionedSchemas,
  Hf as useMarkPADAgreementSigned,
  gf as useMultiWebhookPdWebhookLogsDeliveryAttempts,
  YO as useOptIn,
  HO as useOptOut,
  RO as usePatchBeneficialOwner,
  rm as usePatchBeneficialOwnersId,
  _g as usePatchBusinessIdentitiesId,
  lm as usePatchBusinessOfficersId,
  Hm as usePatchCapitalProductIntents,
  Dm as usePatchCapitalProducts,
  Ol as usePatchCashAdvancePausesP1,
  Zm as usePatchClearWatchlistHitsId,
  Em as usePatchDataingestionStripeIngestions,
  hg as usePatchFlexLoanAge,
  bg as usePatchFlexLoanFund,
  Kg as usePatchFlexLoanOwnership,
  Dg as usePatchLiensId,
  iq as usePatchMerchantBankCardCardholdersId,
  xq as usePatchMerchantBankExternalAccountsId,
  Rq as usePatchMerchantBankExternalBankTransfersId,
  cO as usePatchMerchantBankMerchantConfig,
  aq as usePatchMerchantBankRecipientTransfersId,
  nq as usePatchMerchantBankRecipientsId,
  oO as usePatchMfaExtendSession,
  am as usePatchOwnerIsBeneficialOwner,
  lg as usePatchOwnersId,
  wl as usePatchPartnerConfigsId,
  zl as usePatchPartnerSyncWebhooksId,
  Sl as usePatchPartnerUsers,
  Gf as usePatchPartnerWidgetConfigs,
  Pl as usePatchPartnersId,
  ig as usePatchPersonaFallbackInquiry,
  Xf as usePatchTemplates,
  qg as usePostAmazonRedirectUrl,
  $l as usePostAuthTokens,
  em as usePostBeneficialOwners,
  mg as usePostBusiness,
  _l as usePostBusinessAnnotations,
  Bg as usePostBusinessAuthLink,
  yl as usePostBusinessCores,
  _m as usePostBusinessOfficers,
  Wq as usePostBusinessesNotifications,
  Im as usePostCapitalProductApplication,
  Og as usePostCapitalProductBusiness,
  Tm as usePostCapitalProductNetTopUp,
  Sm as usePostCapitalProductOfferCollection,
  Uq as usePostCardCloseAccount,
  Pg as usePostCards,
  ll as usePostCashAdvanceChargeOffs,
  ql as usePostCashAdvancePauses,
  mm as usePostDataingestionOneschemaGenerateJwt,
  Km as usePostDataingestionS3DatasetIntegrations,
  km as usePostDataingestionS3OrgDatasetIntegrations,
  xm as usePostDataingestionS3RunDatasetIngestion,
  Fm as usePostDataingestionStripeIngestions,
  Eq as usePostDebts,
  Cq as usePostDebtsCheckComplete,
  Fg as usePostDocumentUploadGroup,
  Dq as usePostFinancialReviews,
  sO as usePostForgiveCapitalFee,
  ag as usePostFundingAccounts,
  rO as usePostManualRepayment,
  Nf as usePostMarkAccountingIntegrationSeen,
  jl as usePostMarketingIntegrations,
  Hl as usePostMarketingReview,
  zg as usePostMerchantBankAccounts,
  wf as usePostMerchantBankApplications,
  gg as usePostMerchantBankBusiness,
  yq as usePostMerchantBankCardActivate,
  _q as usePostMerchantBankCardApplePay,
  Oq as usePostMerchantBankCardDisputeChargebackCredit,
  fq as usePostMerchantBankCardDisputeStateTransition,
  qq as usePostMerchantBankCardDisputes,
  mq as usePostMerchantBankCardGooglePay,
  lq as usePostMerchantBankCardStateTransition,
  uq as usePostMerchantBankCards,
  kq as usePostMerchantBankExternalAccounts,
  Bq as usePostMerchantBankExternalBankTransfers,
  $g as usePostMerchantBankInnerTransfers,
  sq as usePostMerchantBankRecipientTransfers,
  Zg as usePostMerchantBankRecipients,
  rq as usePostMerchantBankRecipientsIdPaymentRails,
  zf as usePostMfaOtpSendPersonidPhonenumbertype,
  $f as usePostMfaOtpVerifyPersonidPhonenumbertype,
  aO as usePostMfaVerifyAccountDetails,
  Gq as usePostOfficerSubsidiaryRecords,
  Ag as usePostOptIn,
  Ll as usePostPartnerSyncWebhooks,
  Al as usePostPartnerUsers,
  kl as usePostPartners,
  vg as usePostRestriction,
  Uf as usePostRutterConnections,
  Af as usePostSardineSessionId,
  kg as usePostSetupAmazonTopUp,
  vl as usePostSlackAuthorize,
  fg as usePostSpd,
  Aq as usePostTaxRecords,
  nO as usePostTemplateTypes,
  jf as usePostTemplates,
  zq as usePostTermsOfServiceMarkShown,
  Xm as usePostTriggerRunCelticsChecklist,
  Qf as usePostWebhooksPlaidGeneral,
  Pm as usePutDataingestionS3DatasetIngestionConfigs,
  Kf as useResendCrmAttempt,
  qf as useResendWebhookAttempt,
  EO as useSubmitKYC,
  rg as useUpdateBankAccountInfo,
  AO as useUpdateBoostCompletion,
  SO as useUpdateCardholder,
  Ff as useUpdatePdWebhookEndpoint,
  UO as useUploadBankStatements
};
